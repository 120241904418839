import React, { useEffect, useState } from "react";
import { Table , Alert} from 'flowbite-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faSearch,faFilter, faPlayCircle, faChalkboardUser} from "@fortawesome/free-solid-svg-icons";
import {toggleAddSpeechLabModal} from "../../../features/home/speechLabSlice";
import {setPopup, closePopupEvent} from "../../../features/globalReducer";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ListGroup } from 'flowbite-react';
import { setCurrentStudyGroup } from "../../../features/home/homeSlice";

const SpeechLabList = () => {
    const dispatch = useDispatch();

    const phonicGroups = useSelector((state) => state.homeReducer.phonicGroups);
    const studyGroups = useSelector((state) => state.homeReducer.studyGroups);
    const [openModal, setOpenModal] = React.useState(undefined);
    const [currentPhonicGroup, setCurrentPhonicGroup] = React.useState(undefined);
    const [currentStudyGroup, setCurrentStudyGroup] = React.useState(undefined);
    const openEdit = (index) => {
        // dispatch(toggleAddSpeechLabModal());
        // dispatch message popup say 'eaiting is comming soon'
        dispatch( setPopup( { show: true, title : "Phase 2", message : "Editing coming soon ", color : "info" } ));
    };

    const playHandler = (index) => {
        console.log("playHandler");
        dispatch(
            setPopup(
            {
                show: true,
                title : "Phase 2",
                message : "Playing is not implemented ",
                color : "info"
            }
        ));

        setTimeout(() => {
            dispatch(
                closePopupEvent());
        }
        , 5000);

    };

    const openStudyGroupsPopup = (index) => {
        setOpenModal('default');
        setCurrentPhonicGroup(phonicGroups[index]);
    };

    const selectStudyGroup = (index) => {
        setCurrentStudyGroup(studyGroups[index]);
    };

    const onAssign = () => {
        console.log("onAssign");
        setOpenModal(undefined);
        // GROUP Name with only first 3 Character in Upper case 
        const groupName = currentPhonicGroup.title.substring(0, 3).toUpperCase();
        const data = {
            phonic_group_id : currentPhonicGroup._id,
            study_group_id : currentStudyGroup._id,
            assignment_name : currentPhonicGroup.title,
            assignment_image: currentPhonicGroup.cover,
            assignment_description : `${currentStudyGroup.title} : ${currentPhonicGroup.title} on date${ new Date().toDateString()}`
        };
        dispatch({type: "CREATE_ASSIGNMENT", payload : data});
    };

    const [height, setHeight] = useState(null);
    useEffect(() => {
        const _height = window.innerHeight - 190;
        setHeight(_height);
    }, []);

    return (
        <div className="overflow-y-scroll px-5" style={{height: `${height}px`}} >
            
        <Table hoverable className=" table-auto drop-shadow-md">
                <Table.Head>
                    <Table.HeadCell className="!p-4">
                        
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Name
                    </Table.HeadCell>    
                    <Table.HeadCell>
                        Words
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Tags
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Description
                    </Table.HeadCell>
                    <Table.HeadCell>
                   
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y  ">
                    {phonicGroups.map((phonicGroup, index) => (
                        <Table.Row className="bg-white  hover:bg-teal-50 hover:shadow-2xl" key={index} >
                            <Table.Cell className="!p-4">
                            <button onClick={()=> openStudyGroupsPopup(index)}  className=" text-blue-400 hover:text-orange-700 font-bold py-2 px-4 rounded ml-5">
                                    <FontAwesomeIcon icon={faChalkboardUser} size="2xl" className="mr-3"/>  Assign
                                </button>
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {phonicGroup.title}
                            </Table.Cell>
                            <Table.Cell>
                                {phonicGroup.phonic_ids.length}
                            </Table.Cell>
                            <Table.Cell>
                                {phonicGroup.tags}
                            </Table.Cell>
                            <Table.Cell className=" gap-2">
                                {phonicGroup.description}
                            </Table.Cell>
                            <Table.Cell className=" gap-2">
                                <button onClick={()=> openEdit(index)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                    Edit
                                </button>
                                <button onClick={playHandler}  className=" text-orange-500 hover:text-orange-700 font-bold py-2 px-4 rounded ml-5">
                                    <FontAwesomeIcon icon={faPlayCircle} size="2xl" />
                                </button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Modal show={openModal === 'default'} onClose={() => setOpenModal(undefined)}>
        <Modal.Header> assign speech lab <b>"{currentPhonicGroup && currentPhonicGroup.title}"</b>" to group <b>"{ currentStudyGroup && currentStudyGroup.title }"</b></Modal.Header>
        <Modal.Body>
             <ListGroup>
                { studyGroups.map((studyGroup, index) => (
                    <ListGroup.Item key={index} onClick={()=>{selectStudyGroup(index)}}>
                        {studyGroup.title}
                    </ListGroup.Item> 
                ))}
            </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onAssign()}> Assign </Button>
          <Button color="gray" onClick={() => setOpenModal(undefined) }>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    );
};

export default SpeechLabList;