import { createSlice, createReducer } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: "",
  loginStatus: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    LOGIN: (state, action) => { },
    LOGGIN_SUCCEEDED: (state, action) => {
      state.user = action.payload;
      console.log(`** login success with ${state.user.email}`);
      localStorage.setItem("token", state.user.token);
      
    },
    TOKEN_LOGIN: (state, action) => { },
    CODE_LOGIN: (state, action) => { },
    LOGGIN_FAILED: (state, action) => {
      console.log(`login failed with ${action.payload}`);
      state.loginStatus = false;
    },
    LOGOUT: (state, action) => {
      // remove token from local storage
      localStorage.removeItem("token");
      state.user = null;
      state.loginStatus = state.loginStatus +1;
    },
    REGISTER: (state, action) => { },
    GET_HEADER: (state, action) =>{
      const {token} = state.user;
      console.log(`get header with ${token}`);
      return { Authorization: token ? token : "" };
    }
  },
});
export default userSlice.reducer;
export const { LOGIN, LOGGIN_SUCCEEDED,LOGOUT, LOGGIN_FAILED, CODE_LOGIN, REGISTER, GET_HEADER } = userSlice.actions;

