import config from "../config.js";
import { getAuthHeader } from "./auth_helper";

// seach photo 

const searchPhoto = async (keyword, page, per_page) => {
    // return promise 
    return new Promise(async (resolve, reject) => {     
        try{
            if (!per_page) per_page = 9;
            if (!page) page = 1;
            const header = getAuthHeader();
            const response = await fetch(`${config.URI}/api/photo/search?query=${keyword}&per_page=${per_page}&page=${page}`, {
                method: 'GET',
                headers: header
            });
            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });

}

export { searchPhoto }