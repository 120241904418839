import React, { Component } from 'react'
import ReportActivityCard from './ReportActivityCard';
import ReportAverageCard from './ReportAverageCard';
import ReportProgressCard from './ReportProgressCard';
import ReportQuizDifficulty from './ReportQuizDifficulty';
import { useSelector } from 'react-redux';
import { getClassAverageScore } from '../../../../features/home/reportSlice';




const ReportDashboardTabView = () => {


    return (
        <div className='overflow-y-auto h-4/5'>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 pt-10 pl-8 pr-8">
            <ReportProgressCard />
            <ReportAverageCard  />
            <ReportActivityCard />
        </div>
        <div className="grid grid-cols-1 gap-4 p-10">
            <ReportQuizDifficulty />
        </div>
    </div>
    )
}

export default ReportDashboardTabView  ;    