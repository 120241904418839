import React, { useState, useEffect } from 'react'
import AnalyseCard from '../components/specchPlay/AnalyseCard';
import ScoreCard from '../components/specchPlay/SocreCard';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import SpeechLabImageCardComponent from '../components/specchPlay/SpeechLabImageCardComponent';
import WordCardComponent from '../components/specchPlay/WordCardComponent';
import SpeechLabController from '../components/specchPlay/SpeechLabController';
import SpeechLabProgressBar from '../components/specchPlay/SpeechLabProgressBar';

export default function SpeecbLab() {

    const [showScoreView, setShowScoreView] = useState(false);
    const recorderControls = useAudioRecorder()
    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement("audio");
        audio.src = url;
        audio.controls = true;
        document.body.appendChild(audio);
    };



    
    const [height, setHeight] = useState(null);
    useEffect(() => {
        const _height = window.innerHeight - 104;
        setHeight(_height);
    }, []);


    return (
        <div className='flex flex-col h-screen border-t-2'>
            <SpeechLabProgressBar/>
            {
                //     <div>
                //         <AudioRecorder
                //             onRecordingComplete={(blob) => addAudioElement(blob)}
                //             recorderControls={recorderControls}
                //         />
                //         <button onClick={recorderControls.stopRecording}>Stop recording</button>

                //     </div>
                // </div>
                <>
                <div className="flex justify-center px-10 pb-20" style={{ height: `${height}px` }}>
                   <SpeechLabImageCardComponent showScoreView={showScoreView}/>
                   <WordCardComponent showScoreView={showScoreView}/>
                   <ScoreCard showScoreView={showScoreView} score={80} />
                   <AnalyseCard showScoreView={showScoreView}/>
                </div>
                <SpeechLabController setShowScoreView={setShowScoreView}  showScoreView={showScoreView}/>
                </>
            }
        </div>
    )
}