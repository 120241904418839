import React, { useState, useEffect } from 'react'
import { motion, useAnimation } from "framer-motion";

const SpeechLabProgressBar = () => {
    const [percentage, setPercentage] = useState(20); // Initial percentage value
    const controls = useAnimation();
    useEffect(() => {
        // Animate the progress bar when the percentage changes
        controls.start({ width: `${percentage}%` });
    }, [percentage, controls]);
    return (
        <div className='felx w-full pt-5 px-20'>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 shadow-md">
                <motion.div
                    className="relative h-7 bg-blue-600 text-center text-xs font-medium text-blue-100 p-0.5 leading-none rounded-full flex justify-end"
                    initial={{ width: "0%" }} // Initial width before animation
                    animate={controls}
                    transition={{ duration: 0.5 }} // Animation duration in seconds
                >
                    <div className="w-20 h-20 absolute" style={{ top: "-28px", right: "-15px" }}>
                        <img src="https://s3-ap-southeast-1.amazonaws.com/rgk-images/maxi_face_excited1.png" alt="Face" />
                    </div>
                </motion.div>
            </div>
        </div>
    );
}

export default SpeechLabProgressBar;