
function getAuthHeader() {
    const token = localStorage.getItem("token"); 
    console.log(`getStudyGroups with ${token}`);
    if(!token) return null;
    const headers = {
      'Authorization': token,
      "Content-Type": "application/json",
    };
    return headers;
}

export {getAuthHeader }