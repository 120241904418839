// Reducers and actions for the home page
// -----------------------------------------------------------------------------
import { createSlice, current } from '@reduxjs/toolkit';
import { setPopup } from '../globalReducer';
import studyGroupApi from '../../services/study_group_service';
import api from '../../services/study_group_service';
import { useDispatch } from 'react-redux';
// The initial state of the home page
export const homeSlice = createSlice({
    name: 'home',
    initialState: {
        value: { currentTab: "students" },
        studyGroups: [],
        phonicGroups: [],
        officalSpeechLab: [],
        students: [],
        currentStudyGroup: null,
        assignments: [],
        officalPhonics:[],
        assignmentRecord: null,
        studentAssignmentRecord: null
    },
    reducers: {
        setTab: (state, action) => {
            const newState = { ...state };
            newState.value.currentTab = action.payload;
        },
        initHomeData:  (state, action) => {
           
        },
        setStudents: (state, action) => {
            state.students = action.payload;
        },
        setStudyGroups: (state, action) => {
            state.studyGroups = action.payload;
        },
        UPDATE_CURRENTSTUDYGROUP: (state, action) => {
            console.log("UPDTAE_CURRENTSTUDYGROUP", action.payload);
            state.currentStudyGroup = action.payload;
        },
        setPhonicGroups: (state, action) => {
            state.phonicGroups = action.payload;
        },
        setCurrentStudyGroup: (state, action) => {
            // if action.payload is string 
            // find study group by id 
            // if action.payload is object
            // set current study group to action.payload
            if (typeof action.payload === "string") {
                state.currentStudyGroup = state.studyGroups.find((studyGroup) => studyGroup._id === action.payload);
            } else {
                state.currentStudyGroup = action.payload;
            }
        },
        setAssignments: (state, action) => {
            state.assignments = action.payload;
        },
        setAssignmentRecord: (state, action) => {
            state.assignmentRecord = action.payload;
        },
        setStudentAssignmentRecord : (state, action) => {
            state.studentAssignmentRecord = action.payload;
        },

    }
});
// Action creators are generated for each case reducer function
export const { setTab, initHomeData, setStudents,setCurrentStudyGroup, setStudentAssignmentRecord } = homeSlice.actions;
// The function below is called a selector and allows us to select a value from
export default homeSlice.reducer;

