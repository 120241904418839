
import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import PhonicScoreCard from './PhonicScoreCard';





export default function AnalyseCard({ showScoreView }) {

    return (
        <AnimatePresence>
            {
                showScoreView && <motion.div
                    className="w-1/2 h-full  flex justify-center items-center "
                    whileHover={{ scale: 1.01 }}
                    initial={{ x: "100%", opacity: 0 }} // Initial position, hidden to the right
                    animate={{ x: 0, opacity: 1 }} // Final position, move to the left (in view)
                    transition={{ duration: 0.5, delay: 0.4 }} // Animation duration in seconds
                    // exit={{ x: "-100%", opacity: 0 }}
                >
                    <div className='w-5/6 h-3/5 flex flex-col justify-center items-center shadow-lg  pt-2 bg-slate-100  rounded-xl  text-center '>
                         <ul className="font-bold h-full w-full">
                                <li className="flex flex-row justify-start items-center p-2 ">
                                    
                                    <button  
                                        // onClick={() => handlePlay(studentAssignmentRecord[attemptTab].phonicsRecords[questionTab].sound_uri)}
                                        className="flex text-sm h-8 w-8 rounded-full" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                                        
                                        <FontAwesomeIcon icon={faPlayCircle} className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-red-300 hover:text-red-600 hover:font-extrabold dark:text-white" />
                                      
                                    </button>
                                    Analyse Voice Record [ xx ]
                                </li>
                   
        
                                <PhonicScoreCard phone={"Phone 1"} score={29+13} />
                                <PhonicScoreCard phone={"Phone 2"} score={27} />
                            </ul>
                    </div>
                </motion.div>}

        </AnimatePresence>
    )
}

