
const sampleReports =
    [
        {
            "id": "report1",
            "title": "Math Quiz Report",
            "quiz_id": "quiz1",
            "students_completed": 15,
            "mode": "online",
            "created": "2023-05-10",
            "due_date": "2023-10-15",
            "student_reports": [
                {
                    "id": "student_report1",
                    "student_id": "student1",
                    "quiz_id": "quiz1",
                    "report_id": "report1",
                    "name": "John Doe",
                    "years": 15,
                    "email": "john.doe@example.com",
                    "corrected_amount": 10,
                    "attempt_count": 2,
                    "score": 89,
                    "question_reports": [
                        {
                            "id": "question_report1",
                            "question": "What is 2 + 2?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 4,
                            "correct_answer": 4,

                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "two",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "three",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "four",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "five",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 3 + 3?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 4,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "2",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "3",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "4",
                                    "correct": true
                                },
                                {
                                    "index": 6,
                                    "answer": "5",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 5 + 5?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 1,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "10",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "3",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "four",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "five",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 2 + 2?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 4,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "two",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "three",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "four",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "five",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 3 + 3?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 4,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "2",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "3",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "4",
                                    "correct": true
                                },
                                {
                                    "index": 6,
                                    "answer": "5",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 5 + 5?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 1,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "10",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "3",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "four",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "five",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 2 + 2?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 4,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "two",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "three",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "four",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "five",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 3 + 3?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 4,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "2",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "3",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "4",
                                    "correct": true
                                },
                                {
                                    "index": 6,
                                    "answer": "5",
                                    "correct": false
                                }
                            ]
                        },
                        {
                            "id": "question_report1",
                            "question": "What is 5 + 5?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 1,
                            "correct_answer": 4,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "10",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "3",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "four",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "five",
                                    "correct": false
                                }
                            ]
                        },
                    ]
                },
                {
                    "id": "student_report3",
                    "student_id": "student3",
                    "quiz_id": "quiz3",
                    "report_id": "report3",
                    "name": "Sarah Johnson",
                    "years": 14,
                    "email": "sarah.johnson@example.com",
                    "corrected_amount": 8,
                    "attempt_count": 1,
                    "score": 56,
                    "question_reports": [
                        {
                            "id": "question_report3",
                            "question": "What is the largest planet in our solar system?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 5,
                            "correct_answer": 5,
                            "score": 56, "answers": [
                                {
                                    "index": 1,
                                    "answer": "Mars",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "Venus",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "Mercury",
                                    "correct": false
                                },
                                {
                                    "index": 4,
                                    "answer": "Earth",
                                    "correct": false
                                },
                                {
                                    "index": 5,
                                    "answer": "Jupiter",
                                    "correct": true
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "student_report5",
                    "student_id": "student5",
                    "quiz_id": "quiz1",
                    "report_id": "report",
                    "name": "Emily Wilson",
                    "years": 16,
                    "email": "emily.wilson@example.com",
                    "corrected_amount": 0,
                    "attempt_count": 0,
                    "score": -1,
                    "question_reports": [
                    ]
                }
            ]
        },
        {
            "id": "report2",
            "title": "History Quiz Report",
            "quiz_id": "quiz2",
            "students_completed": 20,
            "mode": "online",
            "created": "2023-05-12",
            "due_date": "2023-05-20",
            "student_reports": [
                {
                    "id": "student_report2",
                    "student_id": "student2",
                    "quiz_id": "quiz2",
                    "report_id": "report2",
                    "name": "Jane Smith",
                    "years": 16,
                    "email": "jane.smith@example.com",
                    "corrected_amount": 18,
                    "attempt_count": 3,
                    "question_reports": [
                        {
                            "id": "question_report2",
                            "question": "Who was the first President of the United States?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 1,
                            "correct_answer": 1,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "George Washington",
                                    "correct": true
                                },
                                {
                                    "index": 2,
                                    "answer": "Thomas Jefferson",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "John Adams",
                                    "correct": false
                                },
                                {
                                    "index": 4,
                                    "answer": "Abraham Lincoln",
                                    "correct": false
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "report3",
            "title": "Science Quiz Report",
            "quiz_id": "quiz3",
            "students_completed": 12,
            "mode": "online",
            "created": "2023-05-15",
            "due_date": "2023-05-20",
            "student_reports": [
                {
                    "id": "student_report3",
                    "student_id": "student3",
                    "quiz_id": "quiz3",
                    "report_id": "report3",
                    "name": "Sarah Johnson",
                    "years": 14,
                    "email": "sarah.johnson@example.com",
                    "corrected_amount": 8,
                    "attempt_count": 1,
                    "question_reports": [
                        {
                            "id": "question_report3",
                            "question": "What is the largest planet in our solar system?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 5,
                            "correct_answer": 5,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "Mars",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "Venus",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "Mercury",
                                    "correct": false
                                },
                                {
                                    "index": 4,
                                    "answer": "Earth",
                                    "correct": false
                                },
                                {
                                    "index": 5,
                                    "answer": "Jupiter",
                                    "correct": true
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "report4",
            "title": "English Quiz Report",
            "quiz_id": "quiz4",
            "students_completed": 18,
            "mode": "online",
            "created": "2023-05-18",
            "due_date": "2023-05-25",
            "student_reports": [
                {
                    "id": "student_report4",
                    "student_id": "student4",
                    "quiz_id": "quiz4",
                    "report_id": "report4",
                    "name": "Michael Brown",
                    "years": 17,
                    "email": "michael.brown@example.com",
                    "corrected_amount": 15,
                    "attempt_count": 2,
                    "question_reports": [
                        {
                            "id": "question_report4",
                            "question": "Who wrote the play 'Romeo and Juliet'?",
                            "correct": true,
                            "attempts": 1,
                            "student_answer": 2,
                            "correct_answer": 2,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "William Wordsworth",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "William Shakespeare",
                                    "correct": true
                                },
                                {
                                    "index": 3,
                                    "answer": "John Milton",
                                    "correct": false
                                },
                                {
                                    "index": 4,
                                    "answer": "Emily Dickinson",
                                    "correct": false
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            "id": "report5",
            "title": "Geography Quiz Report",
            "quiz_id": "quiz5",
            "students_completed": 14,
            "mode": "online",
            "created": "2023-05-20",
            "due_date": "2023-05-27",
            "student_reports": [
                {
                    "id": "student_report5",
                    "student_id": "student5",
                    "quiz_id": "quiz5",
                    "report_id": "report5",
                    "name": "Emily Wilson",
                    "years": 16,
                    "email": "emily.wilson@example.com",
                    "corrected_amount": 12,
                    "attempt_count": 3,
                    "question_reports": [
                        {
                            "id": "question_report5",
                            "question": "Which continent is known as the 'Dark Continent'?",
                            "correct": true,
                            "attempts": 2,
                            "student_answer": 3,
                            "correct_answer": 3,
                            "score": 89, "answers": [
                                {
                                    "index": 1,
                                    "answer": "Asia",
                                    "correct": false
                                },
                                {
                                    "index": 2,
                                    "answer": "Europe",
                                    "correct": false
                                },
                                {
                                    "index": 3,
                                    "answer": "Africa",
                                    "correct": true
                                },
                                {
                                    "index": 4,
                                    "answer": "North America",
                                    "correct": false
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]

export default sampleReports;