import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import chatIcon from "../../assets/icon_chat.png";
import analyseIcon from "../../assets/analyse_icon.png";
import micIcon from "../../assets/mic_icon.png";

const SpeechLabController = ({setShowScoreView, showScoreView}) => {
    const controls = useAnimation();
    const controlsLeft = useAnimation();
    const [percentageRight, setPercentageRight] = useState(100);
    const [percentageLeft, setPercentageLeft] = useState(0);
    // Trigger the animation when the component mounts

    const onTimeerUpdate = () => {
        // time in seconds to percaentage by 100
       
        console.log(percentageLeft);
        if(percentageRight > 0) {
        setPercentageLeft(percentageLeft +10);
        setPercentageRight(percentageRight - 10);
     
        } else {
            setPercentageLeft(0);
            setPercentageRight(100);
        }
        console.log(`percentageLeft: ${percentageLeft} : percentageRight: ${percentageRight}`);

    }



    // useeffect
    useEffect(() => {

        // Animate the progress bar when the percentage changes
        controls.start({ opacity: 1, y: 0 });

    }, []);

    return (
        <div className="absolute bottom-0 left-0 right-0 flex justify-center ">
            <div className="flex flex-col w-full ">
                <motion.div
                    className="flex felx-row space-x-10 justify-center items-center"
                    initial={{ opacity: 0, y: 50 }} // Initial position (hidden and moved down)
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.75 }} // Animation duration and delay
                >
                    <motion.div
                        className="flex justify-center"
                        whileHover={{ scale: 1.1 }
                        } // Add a hover effect (optional)
                    >
                        <div className="w-16 h-16 p-2 hover:bg-lime-500 cursor-pointer  bg-gray-200 rounded-full shadow-md flex justify-center items-center">
                            <img

                                src={chatIcon}
                                alt="Face"
                            />
                        </div>
                    </motion.div>
                    <motion.div
                        onClick={onTimeerUpdate}
                        className="flex justify-center"
                        whileHover={{ scale: 1.1 }} // Add a hover effect (optional)
                    >
                        <div className="w-24 h-24 hover:bg-lime-500 cursor-pointer bg-lime-400 rounded-full shadow-md">
                            <img
                                src={micIcon}
                                alt="microphone"
                            />
                        </div>
                    </motion.div>
                    <motion.div
                        onClick={() => {
                            console.log("Show Score View : " + !showScoreView);
                            setShowScoreView(!showScoreView)
                        
                        }
                        } 
                        className="flex justify-center"
                        whileHover={{ scale: 1.1 }} // Add a hover effect (optional)
                    >
                        <div className="w-16 h-16 p-2 hover:bg-lime-500 cursor-pointer  bg-gray-200 rounded-full shadow-md flex justify-center items-center">
                        <img
                                src={analyseIcon}
                                alt="analyse"
                            />
                        </div>
                    </motion.div>
                </motion.div>
                <div className="w-full h-2 mt-2  flex flex-row">
                    <div className="w-1/2 h-ful bg-lime-500">
                        <motion.div
                            initial={{ width: "100%" }} // Init 
                            // animate={controlsLeft}
                            animate={{ width: `${percentageLeft}%` }}
                            transition={{ duration: 0.5, delay: 0 }}
                            className="h-full bg-white ">
                            
                        </motion.div>
                    </div>
                    <div className="w-1/2 h-ful">
                        <motion.div
                            initial={{ width: "0%" }}
                            // animate={controls}
                            animate={{ width: `${percentageRight}%` }}
                            transition={{ duration: 0.5, delay: 0 }}
                            className="h-full bg-lime-500 ">
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpeechLabController;
