import React from "react";
import { motion, AnimatePresence } from "framer-motion";
const SpeechLabImageCardComponent = ({ showScoreView }) => {
  return (
    <AnimatePresence>
{  !showScoreView &&  (<motion.div
      className="w-1/2 h-full p-4"
      initial={{ x: "100%", opacity:  0 }} // Initial position, hidden to the right
      animate={{ x: 0, opacity: 1}} // Final position, move to the left (in view)
      transition={{ duration: 0.5, delay: 0.35 }} // Animation duration in seconds
      // exit={{ x: "-100%", opacity: 0 } }
    >
      <motion.div 
             whileTap={{ scale: 0.8 }}
             whileHover={{ scale: 1.05 }}
          className="h-full flex flex-col text-center justify-center rounded-lg">
          <img src="https://s3-ap-southeast-1.amazonaws.com/rgk-images/maxi_suit.png" alt="Face" />
      </motion.div>
    </motion.div>)}
    </AnimatePresence>
  );
};

export default SpeechLabImageCardComponent;