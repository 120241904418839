import React from 'react';

class StatusView extends React.Component {
  render() {
    return (
      // <div className="row-start-1 row-end-4 col-span-12 flex justify-around items-center">
      //   <div className="text-gray-700 text-center px-4 py-3 m-1">
      //     <h1 className="text-xl font-extrabold font-sans">
      //       <b>27</b>
      //     </h1>
      //     <div className="flex items-center justify-around pt-3">
      //       <FontAwesomeIcon icon={faUsers} className="mr-2" />
      //       <span>Students</span>
      //     </div>
      //   </div>
      //   <div className="text-gray-700 text-center px-4 py-3 m-1">
      //     <h1 className="text-xl font-extrabold font-sans">
      //       <b>9</b>
      //     </h1>
      //     <div className="flex items-center justify-around pt-3">
      //       <FontAwesomeIcon icon={faComments} className="mr-2" />
      //       <span>SPEAKING</span>
      //     </div>
      //   </div>
      //   <div className="text-gray-700 text-center px-4 py-3 m-1">
      //     <h1 className="text-xl font-extrabold font-sans">
      //       <b>2019</b>
      //     </h1>
      //     <div className="flex items-center justify-around pt-3">
      //       <FontAwesomeIcon icon={faGraduationCap} className="mr-2" />
      //       <span>completed tasks</span>
      //     </div>
      //   </div>
      // </div>
      <dl className=" float-right grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-6 dark:text-white sm:p-8">
      <div className="flex flex-col items-center justify-center">
          {/* <dt className="mb-2 text-3xl font-extrabold">73M+</dt>
          <dd className="text-gray-500 dark:text-gray-400">Student</dd> */}
      </div>
      <div className="flex flex-col items-center justify-center">
          {/* <dt className="mb-2 text-3xl font-extrabold">100M+</dt>
          <dd className="text-gray-500 dark:text-gray-400">Speaking</dd> */}
      </div>
  
      <div className="flex flex-col items-center justify-center   xs:hidden sm:hidden md:hidden xl:block lg:block">
          {/* <dt className="mb-2 text-3xl font-extrabold">1B+</dt>
          <dd className="text-gray-500 dark:text-gray-400">Contributors</dd> */}
      </div>
      
      <div className="flex flex-col items-center justify-center xs:hidden  sm:hidden md:hidden lg:hidden xl:block ">
          {/* <dt className="mb-2 text-3xl font-extrabold">4M+</dt>
          <dd className="text-gray-500 dark:text-gray-400">Suizes</dd> */}
      </div>
  </dl>
    );
  }
}

export default StatusView;
