import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthHeader } from '../services/auth_helper';
import config from '../config';
import { motion } from 'framer-motion';
import SpeechLabImageCardComponent from '../components/specchPlay/SpeechLabImageCardComponent';


export default function QuizEditor() {
    const [isShow, setIsShow] = useState(false);
    const [currentQuiz, setCurrentQuiz] = useState({
        quiz_type: "",
        question: {
            message: "",
            sound_uri: "",
            image_uri: "",
        },
        choices: [
            {
                image_uri: "",
                word: ""
            }
        ],
        index: -1
    });

    const [quizPromp, setQuizPromp] = useState({
        paragraph: "",
        grade: 1,
        choices_count: 3
    });
    const EditorReducer = useSelector(state => state.editorReducer);

    const [question, setQuestion] = useState("");

    const handleAiGenerateQuestion = async () => {
        const header = getAuthHeader();
        // fetch(`${config.URI}/api/quiz/generate`
        setIsShow(true);
        const response = await fetch(`${config.URI}/api/quiz/generate`, {
            method: 'POST',
            headers: header,
            body: JSON.stringify(
                {
                    paragraph: quizPromp.paragraph,
                    grade: quizPromp.grade,
                    choices_count: quizPromp.choices_count
                }
            )
        });
        const data = await response.json();
        setIsShow(false);
        console.log(data);
        setQuestion(data.Question);
        // assign data to currentQuiz
        setCurrentQuiz({
            ...currentQuiz,
            quiz_type: data.Quiz_type,
            question: {
                message: data.Question
            },

        });

        // loop each data.choices and push to currentQuiz.choices
        const choices = [];
        let index = -1;
        data.Choices.forEach(choice => {
            if (index === -1) index = choice.word == data.Answer ? data.Choices.indexOf(choice) : index;
            choices.push({
                word: choice.word
            })
        });
        setCurrentQuiz({
            ...currentQuiz,
            choices: choices,
            index: index
        });
    }

    return (
        <div className=' w-full h-full flex justify-center items-start bg-gray-100'>
            <div className='container h-5/6 overflow-auto'>
                <div className='p-10 w-full  flex flex-col overflow-auto rounded-xl'>
                    {/* form input with quiz_type, image, paragraph, grade, choices_count */}

                    <div className='flex flex-col bg-slate-200 rounded-2xl shadow-xl '>
                        <div className=' w-full text-center'>
                            <h1 className='  text-2xl text-sky-600 drop-shadow-xl mt-1 font-bold'>AI</h1>
                        </div>
                        <div className='flex flex-col px-10 py-2'>
                            {/* <label className='text-lg mb-1'>content to read</label> */}
                            <textarea
                                value={quizPromp.paragraph} onChange={(e) => {
                                    const paragraph = e.target.value
                                    setQuizPromp({
                                        ...quizPromp,
                                        paragraph: paragraph
                                    })
                                }}
                                className=' rounded-md border-0 w-full' placeholder='reading material' />
                        </div>
                        <div className='flex flex-row justify-between px-10 py-2 mb-2'>

                            <div className='flex flex-col'>
                                <label className='text-xs mb-1'>Grade</label>
                                <input
                                    value={quizPromp.grade} onChange={(e) => {
                                        const grade = e.target.value
                                        setQuizPromp({
                                            ...quizPromp,
                                            grade: grade
                                        })
                                    }}
                                    className=' border-0 rounded-lg' type='number' placeholder='student grade' />
                            </div>
                            <div className='flex flex-col'>
                                <label className=' text-xs mb-1'>
                                    Choices
                                    {/* infomation i icon  */}
                                    <svg className='inline-block w-4 h-4 ml-1' fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>information</title><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v2m0 4h.01m-6.938 5.485a9 9 0 1113.856 0A7 7 0 0112.01 20a7 7 0 01-6.949-4.515z"></path></svg>
                                </label>
                                <input
                                    value={quizPromp.choices_count} onChange={(e) => {
                                        const choices_count = e.target.value
                                        setQuizPromp({
                                            ...quizPromp,
                                            choices_count: choices_count
                                        })
                                    }}
                                    className='border-0  rounded-lg' type='number' placeholder='amount of choices' />
                            </div>
                            {/* button in center with text "Ai Generate Question" */}
                            <button
                                onClick={handleAiGenerateQuestion}
                                className=' hover:bg-sky-500 hover:text-slate-100 hover:drop-shadow-xl rounded-lg px-5 py-2 bg-lime-600 text-white shadow-sm'>Ai Generate Question</button>
                        </div>
                    </div>
                </div>
                {currentQuiz.index !== -1 &&
                    <motion.div
                        // from top to appear
                        initial={{ y: 1000 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 1 }}

                        className='p-10 w-full  flex flex-col overflow-auto rounded-xl'>
                        {/* form input for setCurrentQuiz */}
                        <div className='flex flex-col bg-sky-200 rounded-2xl shadow-xl '>
                            <div className=' w-full text-center'>
                                <h1 className='  text-2xl text-sky-600 drop-shadow-xl mt-1 font-bold'>Quiz</h1>
                            </div>
                            <div className='flex flex-col px-10 py-2'>
                                {/* <label className='text-lg mb-1'>content to read</label> */}
                                <textarea
                                    value={question} onChange={(e) => {
                                        const message = e.target.value
                                        setCurrentQuiz({
                                            ...currentQuiz,
                                            question: {
                                                ...currentQuiz.question,
                                                message: message
                                            }
                                        })
                                    }}
                                    className=' rounded-md border-0 w-full' placeholder='question' />
                            </div>

                            <div className='flex flex-row justify-between    space-x-2 px-10 py-2 mb-2'>
                                {/* each element choice button  */}
                                {currentQuiz.choices.map((choice, index) => {
                                    return (
                                        <div key={index} className='flex flex-col'>
                                            <label
                                                className={
                                                    `text-xs mb-1 ${index === currentQuiz.index ? 'text-lime-600' : ''}`
                                                }>
                                                {/* if match answer add check icon */}
                                                {index === currentQuiz.index &&
                                                    <svg className='inline-block w-4 h-4 mr-1' fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>check</title><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                                }
                                                Choice {index + 1}

                                            </label>
                                            <input
                                                value={choice.word} onChange={(e) => {
                                                    const word = e.target.value
                                                    setCurrentQuiz({
                                                        ...currentQuiz,
                                                        choices: currentQuiz.choices.map((choice, i) => {
                                                            if (i === index) {
                                                                return {
                                                                    ...choice,
                                                                    word: word
                                                                }
                                                            }
                                                            return choice
                                                        })
                                                    })
                                                }}
                                                className={
                                                    `border-0 rounded-lg ${index === currentQuiz.index ? ' bg-lime-500' : ''}`
                                                }
                                                type='text' placeholder='choice' />
                                        </div>
                                    )
                                })}

                            </div>
                            {/* save button */}
                            <div className='flex flex-row justify-center mb-5 text-center'>
                                <button
                                    onClick={() => {
                                        console.log(currentQuiz)
                                    }}
                                    className=' hover:bg-sky-500 hover:text-slate-100 hover:drop-shadow-xl rounded-lg px-5 py-2 bg-lime-600 text-white shadow-sm'>Save</button>
                            </div>

                        </div>
                    </motion.div>
                }
            </div>
            {/* cover make loading overlay */}
            { isShow && 
            <div className='w-full h-full bg-gray-300 bg-opacity-50 absolute top-0 left-0 flex justify-center items-center'>
                <div className='flex flex-col justify-center text-center'>
                    <div className=''>
                        {/* AI icon */}
                        <motion.div
                            // animate scale to 0.8 continuously
                            animate={{ scale: [1, 0.95, 1] }}
                            transition={{ duration: 1.5, repeat: Infinity }}
                            className="h-full flex flex-col text-center justify-center rounded-lg">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/rgk-images/maxi_suit.png" alt="Face" />
                        </motion.div>

                    </div>
                    <motion.span 
                    // animate scale to 0.8 continuously
                    animate={{ scale: [1, 0.95, 1] }}
                    >Generating </motion.span>
                </div>
            </div>
            }
        </div>
    )
}