import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import  ACTIONS  from "../../redux/saga_actions";
export default function StudyClassForm({setOpenModal}) {
    const dispatch = useDispatch();
    const currentStudyGroup = useSelector(state => state.homeReducer.currentStudyGroup);
    
    function onClickCreateStudyGroup() {

         // dispatch create study group 
        dispatch({ type: ACTIONS.CREATE_STUDYGROUP  , payload: 
            {
                title: currentStudyGroup.title,
                description: currentStudyGroup.description,
                img_url: currentStudyGroup.img_url,
                tags: currentStudyGroup.tags
            }
        })
        //  setOpenModal(undefined)
    }

    function onClickUpdateStudyGroup() {
        // dispatch update study group
        console.log("on Click Update Study Group", currentStudyGroup);
        if(currentStudyGroup._id != null){
            dispatch({ type: ACTIONS.FETCH_UPDTAE_CURRENTSTUDYGROUP, payload: currentStudyGroup });

        }
    }

    function onClickDeleteStudyGroup() {
        // dispatch delete study group
        dispatch({ type: ACTIONS.DELETE_STUDYGROUP, payload: currentStudyGroup._id });
        console.log("on Click Delete Study Group", currentStudyGroup);
    }


    const onChange_UPDTAE_CURRENTSTUDYGROUP = (data) => {
        console.log("onChange_UPDTAE_CURRENTSTUDYGROUP", data);
        dispatch({ type: ACTIONS.UPDTAE_CURRENTSTUDYGROUP, payload: {
            ...currentStudyGroup,
            ...data
        } });
    }

    return (
        <div className=" absolute  h-full w-screen flex flex-col justify-center items-center align-middle z-10 top-0 bg-zinc-500 bg-opacity-70 ">
        <div className=" h-fit w-fit flex justify-center items-center align-middle bg-white p-5 rounded-md shadow-sm  flex-col">
        <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image Url</label>
                <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Cover Image"
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange_UPDTAE_CURRENTSTUDYGROUP({img_url: e.target.value});
                    }}
                     value={currentStudyGroup.img_url}
                    />
            </div>
           
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Group Name</label>
                <input
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange_UPDTAE_CURRENTSTUDYGROUP({title: e.target.value});
                    } }
                    value={currentStudyGroup.title}
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Group name" />
            </div>
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                <input
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange_UPDTAE_CURRENTSTUDYGROUP({description: e.target.value});

                    } }
                    value={currentStudyGroup.description}
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Description" />
            </div>
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tags</label>
                <input
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onChange_UPDTAE_CURRENTSTUDYGROUP({tags: e.target.value.split(',')});
                    } }
            
                    type="text"
                    value={currentStudyGroup.tags.join(',')}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="tag_0,tag_1,tag_2" />
            </div>
            <div className='w-full gap-2 py-2  flex flex-col  '>
                {  currentStudyGroup._id == null &&
                    <button onClick={() => onClickCreateStudyGroup()}
                        className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Create
                    </button>
                }
                { currentStudyGroup._id != null &&
                    <>
                        <button 

                            onClick={onClickUpdateStudyGroup}
                            className="  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                            Update
                        </button>
                        <button onClick={() => onClickDeleteStudyGroup()}
                            className=" text-white bg-orange-500 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800">
                            Delete
                        </button>
                    </>
                }
                <button onClick={() => setOpenModal(undefined)} className=" text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                    Cancel
                </button>
            </div>

        </div>
    </div>
    )   
}