import { configureStore, MiddlewareArray } from '@reduxjs/toolkit'

import homeReducer from '../features/home/homeSlice'
import reportReducer from '../features/home/reportSlice'
import studentReducer from '../features/studentReducer'
import speechLabReducer from '../features/home/speechLabSlice'
import globalReducer from '../features/globalReducer'
import userReducer from '../features/user/user_slice'

// add saga middleware 
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'
import editorReducer from '../features/editorReducer'
const sagaMiddleware = createSagaMiddleware()

const middleware = new MiddlewareArray().concat(sagaMiddleware)


const store = configureStore({
  reducer: {
    homeReducer : homeReducer,
    reportReducer : reportReducer,
    studentReducer : studentReducer,
    speechLabReducer : speechLabReducer,
    globalReducer : globalReducer,
    userReducer : userReducer,
    editorReducer : editorReducer
  },
  middleware: middleware
})

sagaMiddleware.run(rootSaga)

export default store
