import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSearch } from "@fortawesome/free-solid-svg-icons";

const ReportNav = (props) => {

    // get setShowArchived from props
    const { SetShowArchived, showArchived } = props;

    let inUserActive = "";
    let inUserInactive = "";

    // function on filter click
    const onFilterClick = () => {

        SetShowArchived(!showArchived);
    }
    return (
        <div className="flex flex-col lg:flex-row ">
            <div className="lg:w-1/2  p-4 pb-0.5">
                <div className="flex justify-start items-center flex-row pl-5 pr-5  ">
                    <div class="inline-flex">
                        <button onClick={ ()=> onFilterClick()} className="bg-gray-300 hover:bg-gray-400 text-gray-100  py-2 px-4 rounded-md">
                            {
                                !showArchived ? 
                                <span className=""> 
                                    <FontAwesomeIcon icon={faEyeSlash} className="pr-2" />
                                    Archived
                                </span>  : 
                                <span className="text-slate-600"> 
                                    <FontAwesomeIcon icon={faEye} className="pr-2" />
                                    Archived
                                </span> 
                            }
                        </button>
                    </div>
                </div>

            </div>
            <div className="lg:w-1/2  pt-2 pb-2">
                <div className="flex justify-end items-center flex-row pl-4 pt-2 pr-4">
                    <label className="relative inline">
                        <span className="sr-only">Search Assignment</span>
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <FontAwesomeIcon icon={faSearch} className="pr-3" />
                        </span>
                        <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search" />
                    </label>
                </div>

            </div>
        </div>

    );
};

export default ReportNav;