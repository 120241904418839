import React from "react";

const WaterMark = () => {
    return (
    <div className="w-overlay h-full overflow-hidden "     style={{ pointerEvents: 'none' }}>
      <div className="flex flex-row gap-10 grow">
        <div className='flex flex-col gap-10 grow'>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
        </div>
        <div className='flex flex-col gap-10 grow'>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
        </div>
        <div className='flex flex-col gap-10 grow'>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
        </div>
        <div className='flex flex-col gap-10 grow'>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
          <h1 className="w-watermark font-extrabold text-lg opacity-5">DEMO  DEMO DEMO</h1>
        </div>
      </div>
    </div>
    )
}

export default WaterMark;