import React from "react";
import { HiUser, HiUserGroup } from "react-icons/hi";


const ItemWord = (props) => {
    // get handleAddPhonic from props
    // get currentPhonicGroup from props
    // get setCurrentPhonic from props

    const { handleAddPhonic, currentPhonicGroup, setCurrentPhonic, index, item, showPublic } = props;

    const handleContextMenu = (event, data) => {
        event.preventDefault();
        console.log(`context menu ${data.word_ch}`);
        setCurrentPhonic(data);
    };
    return (
        <li key={item.word_ch}
            onClick={() => handleAddPhonic(item)}
            onContextMenu={(e)=> handleContextMenu(e, item) }
        >
            <a
                className={
                    currentPhonicGroup.phonic_ids.find((_item) => _item._id === item._id) ?
                        "group flex items-center rounded-lg bg-gray-50 p-3 text-base text-gray-300 cursor-not-allowed hover:shadow dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500" :
                        "group flex items-center rounded-lg bg-gray-100 p-3 text-base font-bold text-gray-900 hover:bg-gray-100 hover:shadow dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500"
                }
                href="#"
            >   
                  {
                        showPublic && item.public == true && <HiUserGroup />
                  }
                     {
                        showPublic && item.public == false && <HiUser />
                  }
            
                <span className="ml-3 flex-1 float-left whitespace-nowrap">
                    {item.word_ch}
                </span>
                {item.tags && item.tags.map((tag, index) => (
                    // show if index less than 3
                    index < 3 &&
                    <span
                        className={
                            currentPhonicGroup.phonic_ids.find((_item) => _item._id === item._id) ?
                                "ml-3 inline-flex items-center justify-center rounded bg-gray-50 px-2 py-0.5 text-xs  text-gray-200 dark:bg-gray-700 dark:text-gray-400" :
                                "ml-3 inline-flex items-center justify-center rounded bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500 dark:bg-gray-700 dark:text-gray-400"
                        }
                    >
                        <p>
                            {tag}
                        </p>
                    </span>

                ))}
            </a>

        </li>
    );
};

export default ItemWord;