import React, { useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { motion, AnimatePresence, useMotionValue, useTransform, animate } from "framer-motion";
import ScoreCounter from './ScoreCounter';
import ProgressProvider  from './ProgressProvider.js';
import CircularProgress from './CircleProgress';




export default function ScoreCard({ showScoreView, score }) {
    return (

        <AnimatePresence>
            {
                showScoreView && <motion.div
                    className="w-1/2 h-full  flex justify-center items-center "
                    initial={{ x: "100%", opacity: 0 }} // Initial position, hidden to the right
                    animate={{ x: 0, opacity: 1 }} // Final position, move to the left (in view)
                    transition={{ duration: 0.5, delay: 0.35 }} // Animation duration in seconds
                // exit={{ x: "-100%", opacity: 0 }}
                >

                    <motion.div className='w-3/5 h-3/5 flex flex-col justify-center items-center shadow-lg bg-slate-200 pt-5  rounded-xl  text-center '>
                        <div className='px-4 w-full h-full flex justify-center items-center'>
                            <div className='w-4/6 h-full pb-5 flex text-center justify-center items-center '>
                                <CircularProgress score={score} />
                            </div>
                        </div>

                        <div className='flex flex-col justify-center w-full h-2/5 text-center py-4 rounded-bl-lg rounded-br-lg bg-white'>

                            <h5 className=" text-xl font-bold leading-none text-gray-900 dark:text-white">
                                銀河小孩
                            </h5>
                            <p className="text-lg font-medium text-gray-500 dark:text-gray-400">
                                Yínhé xiǎohái
                            </p>

                        </div>
                    </motion.div>
                </motion.div>}

        </AnimatePresence>
    )
}
