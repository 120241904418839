// Student view of the home page
import React from 'react';
import StudentList from './StudentList';
import StudentConrollerNav from './StudentConrollerNav';
import { useSelector } from 'react-redux';
import AddStudentModal from './AddStudentModal.js';
import AddExistStudentPopup from './AddExistStudentPopup.js';
import { useState } from 'react';


const StudentTab = () => {

    const {showAddStudentModal}  = useSelector(state => state.studentReducer);
    const [isShowExistStudentPopup, setIsShowExistStudentPopup] = useState(false);

    return (
        <div className="bg-white flex-colflex">
           <StudentConrollerNav setIsShowExistStudentPopup={setIsShowExistStudentPopup}/>
           <StudentList/>
           {showAddStudentModal && <AddStudentModal /> }
           {isShowExistStudentPopup && <AddExistStudentPopup setIsShowExistStudentPopup={setIsShowExistStudentPopup} />}
        </div>
    );
}
export default StudentTab;