// saga middleware for redux
// ----------------------------------------------------------------------
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { sagaActions } from './saga_actions';
import { getAuthHeader } from '../services/auth_helper';
import AssignmnetService from '../services/assignment_service';
import config from '../config';
import phonicService from '../services/phonic_services';

import {
    fetchUser,
    fetchUserByTokenInLocalStore,
    fetchUserWithPinCode,
    onLoginSucceeded,
    onFailedLogin,
    createNewUser
} from './saga_auth';
import { 
    fetchStudentsData, fetchGeneratedStudents, updateStudent, fetchStudyGroups,
    FETCH_STUDENTS_BY_STUDYGROUP, ADD_EXIST_STUDENT_BY_CODE, deleteStudent, removeStudentFromStudyGroup
} from './saga_student';
import { act } from 'react-dom/test-utils';
import { de, fa } from '@faker-js/faker';
import { remove } from 'lodash';



function* onLocationChange(action) {

    console.log(`Hello LOCATION_STATE : ${action.payload} : ${window.location.pathname}`);
    const location = action.payload;
    // current subdomain 

    if (`/${location}` !== window.location.pathname) {
        switch (location) {
            case "home":
                console.log("home");
                // yield put(initHomeData());
                break;
            case "login":
                console.log("login");
                break;
            default:
                break;
        }
    }
}

function* HOME_INIT(action) {
    console.log("HOME_INIT from saga");
    // const dispatch = action.payload;
    // dispatch({ type: sagaActions.SET_TAB, payload: "students" });

}

// get assignments by teacher 
function* getAssignmentsByteacher (action) {
    try {
        console.log("getAssignments");
        const url =  config.URI+'/api/assignment/teacher';
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);

    } catch (e) {
        console.log(e);
    }
}

// create post assignmnet with params study_group_id,assignment_name ,assignment_image, assignment_description, phonic_group_id, quiz_id
function* assignAssignmentToStudyGroup (action) {
    try {
        console.log("assignAssignmentToStudyGroup");
        const url =  config.URI+'/api/assignment/create';
        const headers = getAuthHeader();
        const { study_group_id,assignment_name ,assignment_image, assignment_description, phonic_group_id, quiz_id } = action.payload;
        // if both phonic_group_id and quiz_id be null, then return error
        if(!phonic_group_id && !quiz_id) {
            console.log("Please select phonic group or quiz");
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please select phonic group or quiz`, type: "error" } });
            return;
        }
        // if study_group_id be null, then return error
        if(!study_group_id) {
            console.log("Please select study group");
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please select study group`, type: "error" } });
            return;
        }
        // if assignment_name be null, then return error
        if(!assignment_name) {
            console.log("Please input assignment name");
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please input assignment name`, type: "error" } });
            return;
        }
        const body = {
            study_group_id : study_group_id,
            assignment_name : assignment_name,
            assignment_description : assignment_description,
        }
        if(phonic_group_id) {
            body.phonic_group_id = phonic_group_id;
        }
        if(quiz_id) {
            body.quiz_id = quiz_id;
        }
        body["assignment_image"] = assignment_image ? assignment_image : config.defaultImage;
        console.log('onvcall    ', body);
        const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify(body) });
        const data = yield response.json();
        console.log(data);
        // if response is not ok, call popup to show error e
        if (!response.ok) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: response.error ? response.error : "Fail to create", type: "error" } });
            return;
        }
        console.log(data)
        // emit update assignments
        yield put({ type: sagaActions.FETCH_ASSIGNMENTS, payload: data });
        // show success message
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Assignment created successfully`, type: "success" } });
    } catch (e) {
        console.log(e);
    }
}

// get phonic groups by teacher
function* getPhonicGroupsByTeacher (action) {
    try {
        console.log("getPhonicGroupsByTeacher");
        const url =  config.URI+'/api/phonic/groupbyteacher';
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        yield put({ type: 'home/setPhonicGroups', payload: data });
    } catch (e) {
        console.log(e);
    }
}

// get phonic group officail /api/officialphonicgroups?page=10
function* getPhonicGroupOfficial (action) {
    try {
        console.log("getPhonicGroupOfficial");
        const page = action.payload;
        const url =  config.URI+`/api/officialphonicgroups?page=${page}`;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // emit update setOfficalPhonicGroups
        yield put({ type: 'speechLab/setOfficalPhonicGroups', payload: data });
    } catch (e) {
        console.log(e);
    }
}
// get phonics word by tacher 
function* getPhonicsWordByTeacher (action) {
    try {
        console.log("getPhonicsWordByTeacher");
        const url =  config.URI+'/api/phonic/teacher';
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        yield put({ type: 'speechLab/setMyPhonics', payload: data });
    } catch (e) {
        console.log(e);
    }
}
// search offical phonics /api/officialphonicgroups/search?search_text={search_text}
function* searchOfficalPhonics (action) {
    try {
            console.log("searchOfficalPhonics");
            const search_text = action.payload;

            const url =  config.URI+`/api/phonic/search?search_text=${search_text}`;
            const headers = getAuthHeader();
            const response = yield call(fetch, url, { headers });
            const data = yield response.json();
            console.log(data);
            // emit update 
            yield put({ type: 'speechLab/setSearchPhonics', payload: data });

    } catch (e) {
        console.log(e);
    }
}

function* FETCH_PHONIC_PUBLIC (action) {
    try {
        console.log("FETCH_PHONIC_PUBLIC");
        const page = action.payload;
        const url =  config.URI+`/api/phonic/public?page=${page}`;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        // emit update to 
        yield put({ type: 'speechLab/setPublicPhonics', payload: data });
    }
    catch (e) {
        console.log("error FETCH_PHONIC_PUBLIC");
        console.log(e);
    }
}

// get phonic offical 
function* getPhonicsWordOfficial (action) {
    try {
        const {page, limit} = action.payload;
        console.log("getPhonicsWordOfficial");
        // if limit is null, then set limit = 10
       const _limit = limit ? limit : 12;
       const _page = page ? page : 1;
            
        
        const url =  config.URI+`/api/phonic/galaxy?limit=${_limit}&page=${_page}`;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        yield put({ type: 'speechLab/setOfficalPhonics', payload: data });

    } catch (e) {
        console.log(e);
    }
}
// create phonic group 
function* createPhonicGroup (action) {
    try {
        console.log("createPhonicGroup");
        const url =  config.URI+'/api/phonic/creategroup';
        const headers = getAuthHeader();
        const { title, cover, tags,phonic_ids } = action.payload;
        const {file} = action.payload;
        // if title or phonic_ids be null, then return error
        if(!title || !phonic_ids) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please input title and phonic words`, type: "error" } });
            return;
        }
        const formData = new FormData();
        let _phonic_ids = phonic_ids;
        // if phonic_ids is not array, then convert to array
        if(!Array.isArray(phonic_ids)) {
            _phonic_ids = phonic_ids.split(',');
        }
        formData.append('title', title);
  
        formData.append('phonic_ids', _phonic_ids);
        formData.append('cover', cover ? cover : config.defaultImage);
        formData.append('tags', tags ? tags : []);

        // if file is not null, then append file to body
        if(file) {
            formData.append('file', file);

        }
        // fetch api post 
        // console.log(JSON.stringify(formData));
       
        const response = yield call(fetch, url, { headers: {
            'Authorization': headers.Authorization
        }, method: 'POST', body: formData });
        // const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify(body) });
        const data = yield response.json();
        console.log(data);
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Phonic group created successfully`, type: "success" } });
        // if status is 200, then toggle  speech lab modal
        if(response.status === 201) {
            // emit update study groups
            yield put({ type: 'speechLab/toggleAddSpeechLabModal' });
            // emit update fetch phonic groups
            yield put({ type: sagaActions.FETCH_PHONICGROUP });
            // emit message popup say 'new phonic group created successfully'
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Phonic group created successfully`, type: "success" } }); 
        }
    } catch (e) {
        console.log(e);
    }
}
// create phonic 
function* createPhonic (action) {
    try {
        console.log("createPhonic");

        const { word, img_uri, mic_duration, phonic_sound, is_sprite_sheet, system_phone_ch, sprite_sheet_data, tags, voice_name, word_ch, community } = action.payload;
        const {file} = action.payload;
        // if title or phonic_ids or img_uri  be null, then return error
        if(!word_ch) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please input word and image`, type: "error" } });
            return;
        }
        const body = {
            word : word,
            img_uri : img_uri,
        }
        body['mic_duration'] = mic_duration ? mic_duration : 4;
        body['phonic_sound'] = phonic_sound ? phonic_sound : "";
        body['is_sprite_sheet'] = is_sprite_sheet ? is_sprite_sheet : false;
        body['system_phone_ch'] = system_phone_ch ? system_phone_ch : "";
        body['sprite_sheet_data'] = sprite_sheet_data ? sprite_sheet_data : [];
        body['tags'] = tags ? tags : [];
        body['voice_name'] = voice_name ? voice_name : "";
        body['word_ch'] = word_ch ? word_ch : "";
        body['community'] = community ? community : false;
        // if file is not null, then append file to body
        if(file) {
            body['file'] = file;
        }

        const data = yield call(phonicService.createPhonic, body);
        
        // const data = yield response.json();
        console.log(data);
        // emit update refresh sppech lab my phonic event
        yield put({ type: sagaActions.FETCH_TEACHER_PHONICS });
        yield put({ type: sagaActions.FETCH_PHONIC_PUBLIC})
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Phonic created successfully`, type: "success" } });
        
    } catch (e) {
        console.log(e);
    }
}
// update phonic group PUT
function* updatePhonicGroup (action) {
    try {
        console.log("updatePhonicGroup");
        const url =  config.URI+'/api/phonicgroup/update';
        const headers = getAuthHeader();
        const { title, cover, tags,phonic_ids, id } = action.payload;
        // if title or phonic_ids be null, then return error
        
        const body = {
            title : title,
        }
        body['cover'] = cover ? cover : config.defaultImage;
        body['tags'] = tags ? tags : [];
        const response = yield call(fetch, url, { headers, method: 'PUT', body: body });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        // yield put({ type: sagaActions.SETPHONICGROUPS, payload: data });

    } catch (e) {
        console.log(e);
    }
}

// add phonic to group POST
function* addPhonicToGroup (action) {
    try {
        console.log("addPhonicToGroup");
        const url =  config.URI+'/api/phonicgroup/addphonictogroup';
        const headers = getAuthHeader();
        const { phonic_id, group_id } = action.payload;
        // if phonic_id or group_id be null, then return error
        if(!phonic_id || !group_id) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please select phonic and group`, type: "error" } });
            return;
        }
        const body = {
            phonic_group_id : group_id,
            phonic_id : phonic_id,
        }
        const response = yield call(fetch, url, { headers, method: 'POST', body: body });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        // yield put({ type: sagaActions.SETPHONICGROUPS, payload: data });
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Phonic added to group successfully`, type: "success" } });
    } catch (e) {
        console.log(e);
    }
}

// ***************** ASSIGNMENT *****************
// get assignment by teacher
function* getAssignmentsByTeacher (action) {
    try {
        console.log("getAssignmentsByTeacher");
        const url =  config.URI+'/api/assignment/byteacher';
        const headers = getAuthHeader();
        const { teacher_id } = action.payload;
        // if teacher_id be null, then return error
        if(!teacher_id) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Please select teacher`, type: "error" } });
            return;
        }
 
        const response = yield call(fetch, url, { headers, method: 'GET' });
        const data = yield response.json();
        console.log(data);
        // response status 200
        if(response.status === 200) {
            // emit update assignments
            yield put({ type: sagaActions.SETASSIGNMENTS, payload: data });
        } else {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Get assignments failed`, type: "error" } });
        }
    } catch (e) {
        console.log(e);
    }
}


// get assignment by teacher
function* fetchAssignmentByTeacher (action) {
    try {
        console.log("getAssignmentsByTeacher");
        const url =  config.URI+'/api/assignment/teacher';
        const headers = getAuthHeader();
        // get user id from redux store


        const response = yield call(fetch, url, { headers, method: 'GET' });
        const data = yield response.json();
        console.log(data);
        // response status 200
        if(response.status === 200) {
            // emit update assignments
            yield put({ type: sagaActions.SETASSIGNMENTS, payload: data });
        } else {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Get assignments failed`, type: "error" } });
        }
    } catch (e) {
        console.log(e);
    }
}

// CREATE_STUDYGROUP
function* createStudyGroup (action) {
    try {
        console.log("createStudyGroup");
        const url =  config.URI+'/api/studygroup';
        const headers = getAuthHeader();
        const { title, description, level, img_url } = action.payload;
        // if title or phonic_ids be null, then return error
        console.log(action.payload)

        const body = {
            title : title,
            description : description,
            img_url: config.defaultImage,
            level : "none"
        }
        body.img_url = img_url ? img_url : config.defaultImage;
        body.level = level ? level : "none";

        const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify(body) });
        const data = yield response.json();
        console.log(data);
        // if response ok then emit fetch study groups
        if(response.status === 200) {
            // emit update study groups
            yield put({ type: sagaActions.FETCH_STUDYGROUPS });
            
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Study group created successfully`, type: "success" } });
        } else {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Create study group failed`, type: "error" } });
        }
    } catch (e) {
        console.log(e);
    }
}


// UPDTAE STUDYGROUP
function* fetchUpdateStudyGroup (action) {
    try {

        console.log("createStudyGroup");
        const body = action.payload;
        const url =  config.URI+'/api/studygroup/'+body._id;
        const headers = getAuthHeader(); 
        const response = yield call(fetch, url, { headers, method: 'PUT', body: JSON.stringify(body) });
        const data = yield response.json();
          // if response ok then emit fetch study groups
        if(response.status === 200) {
            // emit update study groups
            yield put({ type: sagaActions.FETCH_STUDYGROUPS });
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Study group updated successfully`, type: "success" } });
        }else{
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Update study group failed`, type: "error" } });
        }

    } catch (e) {
        console.log(e);
    }
}

//deleteStudyGroup 
function* deleteStudyGroup (action) {
    try {
        console.log("deleteStudyGroup");
        const url =  config.URI+'/api/studygroup/'+action.payload;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers, method: 'DELETE' });
        const data = yield response.json();
        console.log(data);
        if(response.status === 200) {
            yield put({ type: sagaActions.FETCH_STUDYGROUPS });
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Study group deleted successfully`, type: "success" } });
        } else {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Delete study group failed`, type: "error" } });
        }
    } catch (e) {
        console.log(e);
    }
}

// *****************  *****************

// view assignment record by assignment id
function* FETCH_ASSIGNMENT_RECORED (action) {
    try {
        console.log("viewAssignmentRecord");
        const  _id  = action.payload;
        const record = yield AssignmnetService.RecordService.getAssignmentRecordAnalyse(_id);
        console.log(record);
        // emit update assignment record
        // reportSlice/viewReport 
        yield put({ type: 'home/setAssignmentRecord', payload: record });
        // OpenSubPage 
        yield put({ type: 'report/OpenSubPage', payload: "summary" });
    }
    catch (e) {
        console.log(e);
    }
}

// FETCH_ASSIGNMENT_RECORED BY STUDENT ID
function* FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID (action) {
    try {
        console.log("FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID");
        const  {student_id, assignment_id}  = action.payload;
        const studentAssignmentRecord = yield AssignmnetService.RecordService.getAssignmentRecordByStudentIdAndAssignmentId(student_id, assignment_id);
        console.log(studentAssignmentRecord);
        // emit update assignment record
        yield put({ type: 'home/setStudentAssignmentRecord', payload: studentAssignmentRecord });
        // yield put({ type: 'report/openStudentReport', payload: studentAssignmentRecord });
        // yield put({ type: 'report/OpenSubPage', payload: "students" });
    }
    catch (e) {
        console.log(e);
    }
}


// SET_ARCHIVE_ASSIGNMENT 
function* SET_ARCHIVE_ASSIGNMENT (action) {
    try {
        console.log("SET_ARCHIVE_ASSIGNMENT");
        const  {assignment_id, archived }  = action.payload;
        // set assignment archive by AssignmentService setArchiveAssignment 
        const assignment = yield AssignmnetService.setArchiveAssignment(assignment_id, archived);
        console.log(assignment);
        // if assignment is null display error message
        // else emit update assignments
        if(assignment === null) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Archive assignment failed`, type: "error" } });
        } else {
            yield put({ type: sagaActions.FETCH_ASSIGNMENTS,  });
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Archive assignment successfully`, type: "success" } });
        }
    }

    catch (e) {
        console.log(e);
    }
}

// setStudyGroups
function* onSetStudyGroups (action) {
    const data = action.payload;
    if(data.length > 0) {
        yield put({ type: 'home/setCurrentStudyGroup', payload: data[0]._id })
        yield put ({ type: 'FETCH_STUDENTS_BY_STUDYGROUP', payload: data[0]._id })
    }
}




// INIT SPEECH LAB 

function* INIT_SPEECH_LAB (action) {
    try {
        console.log("initSpeechLab");
        yield put( {type: 'FETCH_OFFICAL_PHONICS', payload : {page: 1, limit: 12 }});
        yield put( {type: 'FETCH_OFFICAL_PHONICS_GROUP'});
        yield put( {type: 'FETCH_TEACHER_PHONICS'});
        yield put( {type: 'FETCH_PHONIC_PUBLIC'});
    }
    catch (e) {
        console.log(e);
    }
}

function* galaxySaga() {
    // ------------------ PHONIC ------------------
    yield takeLatest(sagaActions.INIT_SPEECH_LAB, INIT_SPEECH_LAB);
    yield takeLatest(sagaActions.FETCH_OFFICAL_PHONICS, getPhonicsWordOfficial);
    yield takeLatest(sagaActions.FETCH_TEACHER_PHONICS, getPhonicsWordByTeacher);
    yield takeLatest(sagaActions.FETCH_PHONIC_PUBLIC, FETCH_PHONIC_PUBLIC);
    yield takeLatest(sagaActions.FETCH_OFFICAL_PHONICS_GROUP, getPhonicGroupOfficial);
    yield takeLatest(sagaActions.CREATE_PHONIC, createPhonic);
    yield takeLatest(sagaActions.CREATE_PHONIC_GROUP, createPhonicGroup);
    yield takeLatest(sagaActions.UPDATE_PHONIC_GROUP, updatePhonicGroup);
    yield takeLatest(sagaActions.SEARCH_OFFICAL_PHONICS, searchOfficalPhonics);


    // ------------------ PHONIC GROUP ------------------
    yield takeLatest(sagaActions.FETCH_PHONICGROUP, getPhonicGroupsByTeacher);
    yield takeLatest(sagaActions.CREATE_ASSIGNMENT, assignAssignmentToStudyGroup);
    yield takeLatest(sagaActions.FETCH_ASSIGNMENTS, fetchAssignmentByTeacher);
    yield takeLatest(sagaActions.CREATE_STUDYGROUP, createStudyGroup);
    yield takeLatest(sagaActions.FETCH_LOGIN_SAGA, fetchUser);
    yield takeLatest(sagaActions.FETCH_TOKEN_LOGIN_SAGA, fetchUserByTokenInLocalStore);
    yield takeLatest(sagaActions.LOGGIN_SUCCEEDED, onLoginSucceeded);
    yield takeLatest(sagaActions.USER_LOGGIN_FAILED, onFailedLogin);
    yield takeLatest(sagaActions.CODE_LOGIN, fetchUserWithPinCode);
    yield takeLatest(sagaActions.REGISTER, createNewUser);
    yield takeLatest(sagaActions.LOCATION_STATE, onLocationChange);
    yield takeLatest(sagaActions.HOME_INIT, HOME_INIT);
    yield takeLatest(sagaActions.FETCH_STUDENTS, fetchStudentsData);
    yield takeLatest(sagaActions.FETCH_GEN_STUDENT, fetchGeneratedStudents);
    yield takeLatest(sagaActions.FETCH_UPDATE_STUDENT, updateStudent);
    yield takeLatest(sagaActions.FETCH_STUDYGROUPS, fetchStudyGroups);
    yield takeLatest(sagaActions.FETCH_STUDENTS_BY_STUDYGROUP, FETCH_STUDENTS_BY_STUDYGROUP);  
    yield takeLatest(sagaActions.FETCH_ASSIGNMENT_RECORED, FETCH_ASSIGNMENT_RECORED);
    yield takeLatest(sagaActions.FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID, FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID);
    yield takeLatest(sagaActions.SET_ARCHIVE_ASSIGNMENT, SET_ARCHIVE_ASSIGNMENT);
    yield takeLatest(sagaActions.ADD_EXIST_STUDENT_BY_CODE, ADD_EXIST_STUDENT_BY_CODE);
    yield takeLatest('home/setStudyGroups', onSetStudyGroups);
    yield takeLatest(sagaActions.FETCH_UPDTAE_CURRENTSTUDYGROUP, fetchUpdateStudyGroup);
    yield takeLatest(sagaActions.DELETE_STUDYGROUP, deleteStudyGroup);
    yield takeLatest(sagaActions.FETCH_DELETE_STUDENT, deleteStudent);
    yield takeLatest(sagaActions.REMOVE_STUDENT_FROM_STUDYGROUP, removeStudentFromStudyGroup);
}

export default galaxySaga;