import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faQrcode } from '@fortawesome/free-solid-svg-icons';
import QRCode from "react-qr-code";
import { useRef } from 'react';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';
import { useDispatch } from 'react-redux';




const QRDisplay = (props) => {
   const { data, name, setShowQR } = props;

   console.log( `data : ${data} , name : ${name}`);

    const downloadQRCode = async (name) => {
        // Generate download with use canvas and stream
        const element = document.getElementById("qr-gen");
        const canvas  = await html2canvas(element);
        const dataURL = canvas.toDataURL("image/png");
        downloadjs(dataURL, `${name}-${Date.now()}.png`);
    }
    const close = () => {
        setShowQR(false);
    }

    return (
        <div className=" z-30 absolute w-screen h-screen top-0 left-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className='bg-white rounded-lg p-4'>
                <div className="flex  items-center mb-4 justify-end">
                    <button onClick={close} className="text-2xl font-bold">&times;</button>
                </div>
                <div className="flex justify-center items-center mb-0">
                    <div id="qr-gen" className="w-64 h-64 flex flex-col justify-center items-center p-4">
                        <QRCode value={data} />
                        name : {name}
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <button onClick={() => downloadQRCode('qr-gen')} className="flex justify-center items-center bg-blue-500 text-white rounded-lg px-4 py-2 mt-4">
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                        Download
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QRDisplay;