import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    page: 'editor',
    QUIZTYPES: [
        "Reading",
        "Listening",
        "Speaking",
        "Writing"
    ],
    quiz_type: "",
    question: {
        message: "message",
        sound_uri: "",
        image_uri: "",
    },
    choices: [
        {
            image_uri: "",
            word: "word 1 "
        },
        {
            image_uri: "",
            word: "word 2 "
        }
    ],
    index: -1
};

const EditorReducer = createSlice({
    name: 'editor',
    initialState,
    reducers: {
   
    }
});


export const {  } = EditorReducer.actions;
export default EditorReducer.reducer;