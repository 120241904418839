import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { faXmark, faBriefcase, faCheck, faCircleCheck, faPlayCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {setStudentAssignmentRecord } from '../../../../features/home/homeSlice';

const ReportAssignmentPerformance = (props) => {
    const studentAssignmentRecord = useSelector(state => state.homeReducer.studentAssignmentRecord);
    const assignments = useSelector(state => state.homeReducer.assignments);
    const [attemptTab, setAttemptTab] = React.useState(0);
    const [questionTab, setQuestionTab] = React.useState(0);
    const [assignmentName, setAssignmentName] = React.useState("");
    const dispatch = useDispatch();
    const handleAttemptTab = (index) => {
        setAttemptTab(index);
    }

    const handleQuestionTab = (index) => {
        setQuestionTab(index);
    }

    const handlePlay = (url) => {
        console.log("play");
        const audio = new Audio(url);
        audio.play();
    }

    const handleClose = () => {
        console.log("close");

        dispatch(setStudentAssignmentRecord(null));
    }

    const convertDate = (date) => {
        const inputDate = new Date(date);
        const day = String(inputDate.getDate()).padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[inputDate.getMonth()];
        const year = inputDate.getFullYear();

        const formattedDate = `${day} ${month} ${year}`;
        return formattedDate;
    }

    const getScoreColour = (score) => {
        if (score > 70) {
            return "bg-lime-400 rounded-full h-2.5 dark:bg-lime-400";
        } else if (score > 25) {
            return "bg-yellow-400 rounded-full h-2.5 dark:bg-yellow-400";
        } else {
            return "bg-red-400 rounded-full h-2.5 dark:bg-red-400";
        }
    }





    return (
        <div className="h-screen w-screen fixed top-0 left-0 bg-slate-500">
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start">
                            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                                <span className="sr-only">Open sidebar</span>
                                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                </svg>
                            </button>
                            <a className="flex ml-2 md:mr-24">

                                <FontAwesomeIcon icon={faBriefcase} className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white" />
                                <span className="ml-5 self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                                     Analyse
                                </span>
                            </a>
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center ml-3">
                                <div>
                                    <button type="button" className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                                        <span className="sr-only">Open menu</span>

                                    </button>
                                </div>
                                <div className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-user">
                                    <div className="px-4 py-3" role="none">
                                        <p className="text-sm text-gray-900 dark:text-white" role="none">
                                            Neil Sims
                                        </p>
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                                            neil.sims@flowbite.com
                                        </p>
                                    </div>
                                    <ul className="py-1" role="none">
                                        <li>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Dashboard</a>
                                        </li>
                                        <li>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Settings</a>
                                        </li>
                                        <li>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Earnings</a>
                                        </li>
                                        <li>
                                            <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Sign out</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center ml-3">
                            <button 
                                onClick={handleClose}
                                type="button" className="flex text-sm h-8 w-8 rounded-full" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                                <FontAwesomeIcon icon={faXmark} className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-red-300 hover:text-red-600 hover:font-extrabold dark:text-white" />
                            </button>
                        </div>
                    </div>

                </div>
            </nav>

            <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-slate-200 border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
                <div className="h-full px-3 pb-4  overflow-y-auto  dark:bg-gray-800">
                    <ul className="space-y-1 font-medium">
                    
                         {studentAssignmentRecord && studentAssignmentRecord.map((_studentAssignmentRecord, index) => ( 
                            <li key={_studentAssignmentRecord._id} 
                            onClick={() => handleAttemptTab(index)}
                            className={
                                attemptTab == index ? 
                                "flex flex-row border-r border-b bg-white text-lime-700  justify-start items-center p-2 rounded-xl cursor-pointer" :    
                                "flex flex-row border-r border-b bg-slate-100 text-slate-400 justify-start items-center p-2 hover:bg-slate-50 rounded-xl cursor-pointer"
                            }>
                                
                               { attemptTab == index &&
                                <FontAwesomeIcon icon={faCircleCheck} className="self-center text-sm font-semibold sm:text-xl whitespace-nowrap dark:text-white" />
                                }   
                                { attemptTab !== index &&
                                  <FontAwesomeIcon icon={ faCircle} className="self-center text-sm font-semibold sm:text-xl whitespace-nowrap dark:text-white" />
                                }  
                                <p className="ml-4">
                                    <h6 className=""> Attempt {index + 1} </h6>
                                    <span className="font-extralight text-sm"> { convertDate(_studentAssignmentRecord.created_at)} </span>
                                </p>
                                <span className="ml-auto text-sm  font-bold"> {_studentAssignmentRecord.score} % </span>
                            </li>    
                        ))
                        }
              
                          
                    </ul>
                </div>
            </aside>

            <div className=" p-4 sm:ml-64">
                <div className=" bg-slate-100 h-[calc(100vh-80px)] border-gray-200 rounded-lg  dark:border-gray-700 mt-14">
                    <div className="grid grid-cols-4  mb-4 h-full">
                        <div className="col-span-1 p-2 border-r overflow-y-auto">
                            <ul className="space-y-1 font-medium bg-slate-50">
                                <li className="flex flex-row justify-start items-center p-2 ">
                                    Questions
                                </li>
                                {studentAssignmentRecord.length > attemptTab && studentAssignmentRecord[attemptTab].phonicsRecords.map((record, index) => ( 
                         
                                <li key={record.phonic_id} 
                                    onClick={() => handleQuestionTab(index)}
                                    className={ index == questionTab ? 
                                        "flex flex-row border-r border-b bg-white justify-start items-center p-2 rounded-xl" :
                                        "flex flex-row border-r border-b text-slate-500 bg-slate-200 justify-start items-center p-2 hover:bg-slate-400 rounded-xl cursor-pointer"
                                        }>
                                    <div className="self-center   whitespace-nowrap  font-light">
                                        {index + 1}
                                    </div>
                                    <p className="ml-4">
                                        <span className=" text-sm"> {record.text} </span>
                                    </p>
                                    <span className="ml-auto text-sm font-bold"> {record.score}% </span>
                                </li>
                                )) 
                                }
                            </ul>
                        </div>
                        <div className="col-span-3 rounded-lg bg-white px-4">
                            <ul className="space-y-1 font-bold">
                                <li className="flex flex-row justify-start items-center p-2 ">
                                    
                                    <button  
                                        onClick={() => handlePlay(studentAssignmentRecord[attemptTab].phonicsRecords[questionTab].sound_uri)}
                                        className="flex text-sm h-8 w-8 rounded-full" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                                        
                                        <FontAwesomeIcon icon={faPlayCircle} className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-red-300 hover:text-red-600 hover:font-extrabold dark:text-white" />
                                      
                                    </button>
                                    Student Voice Record [{studentAssignmentRecord[attemptTab].phonicsRecords[questionTab].text}]
                                </li>
                                {studentAssignmentRecord.length > attemptTab && studentAssignmentRecord[attemptTab]
                                .phonicsRecords[questionTab].break_down.map((soundRecord, index) => ( 
                         
                                <li className="flex flex-row border justify-start items-center p-2 rounded-xl cursor-pointer bg-white">
                                    <div className="ml-4 text-left w-32">
                                        <span className="font-medium  text-md"> {soundRecord.phonic_sound} </span>
                                    </div>
                                    <div className=" ml-4 mr-3 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                        <div className={
                                            getScoreColour(soundRecord.score)
                                        } style={{ width: `${soundRecord.score}%` }}></div>
                                    </div>
                                    <span className="ml-auto text-sm text-lime-400 font-bold"> {soundRecord.score}% </span>
                                </li>
                                ))
                                }
                     

                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )


}


export default ReportAssignmentPerformance;