import react from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OpenSubPage } from '../../../features/home/reportSlice';

const ReportInfoNav = () => {

    const {reportViewSubPage} = useSelector(state => state.reportReducer);
    const dispatch = useDispatch();
    const handleViewReportSubPage = (subPage) => () => {
        console.log(`subPage: ${subPage}`);
        dispatch( OpenSubPage(subPage) );
    };

    return (
        <div className=" border-b border-gray-200 dark:border-gray-700 rounded-md">
            <ul className="flex flex-wrap justify-center -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li className="mr-2" onClick={handleViewReportSubPage('summary')}>
                    <a href="#" className={reportViewSubPage === "summary" ? "bg-white  text-sky-700 font-extrabold inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group" : 
                    "inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"}>
                        SUMMARY
                    </a>
                </li>
                <li className="mr-2" onClick={handleViewReportSubPage('students')}>
                <a href="#" className={reportViewSubPage === "students" ? "bg-white text-sky-700 font-extrabold inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group" : 
                    "inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"}>
                        STUDENTS
                    </a>
                </li>
                <li className="mr-2" onClick={handleViewReportSubPage('questions')}>
                <a href="#" className={reportViewSubPage === "questions" ? "bg-white  text-sky-700 font-extrabold inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group" : 
                    "inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"}>
                        QUESTIONS
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default ReportInfoNav;