import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect } from "react";

const ScoreCounter = ({ score }) => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  useEffect(() => {
    const animation = animate(count, score, { duration: 1.5 });

    return animation.stop;
  }, []);

  return <motion.h1 className="font-extrabold text-xl">{rounded}</motion.h1>;
}

export default ScoreCounter;