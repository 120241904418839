import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeStudentReport, closeViewReport } from '../../../features/home/reportSlice';
import { Avatar } from 'flowbite-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faFolderOpen, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { Table } from 'flowbite-react';


const StudentReportView = () => {
    // const { currentStudentReport, currentReport } = useSelector(state => state.reportReducer);
    const {assignmentRecord} = useSelector(state => state.homeReducer);
    const dispatch = useDispatch();


    return (
        <div className=" bg-white shadow-lg z-20  h-full w-full" >
            <div className="flex flex-col bg-cover bg-center bg-[url('https://i.pinimg.com/564x/d8/1f/c3/d81fc314221b7143aea783e1d0f0dfa1.jpg')]">
            <nav className="flex p-3" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                        <a onClick={() => dispatch(closeViewReport())}  className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            Home
                        </a>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                            <span onClick={() => dispatch(closeStudentReport())}  className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                {assignmentRecord ? assignmentRecord.title : "no data"}
                            </span>
                        </div>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg aria-hidden="true" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                            <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                {/* {currentStudentReport.name ? currentStudentReport.name : "no data"} */}
                            </span>
                        </div>
                    </li>
                </ol>
            </nav>
                <div className="flex flex-row items-center pr-5">
                    <div className="flex flex-col items-center text-white p-5 text-center mr-5">
                        <Avatar img='https://i.pinimg.com/564x/e5/2b/c1/e52bc164702554060a1b682d05821952.jpg' className="pb-3" size='xl' bordered rounded />
                        {/* <span className=" text-xl font-extrabold"> {currentStudentReport.name} </span> */}
                        {/* {currentStudentReport.email} */}
                    </div>
                    <div className="flex flex-row justify-center gap-5 w-full items-center text-center">
                        <div className="flex flex-col items-center bg-slate-50  opacity-90 shadow-md p-5 text-center rounded-md">
                            <div class="flex flex-col items-center justify-center h-full">
                                <dt class="mb-5 text-5xl font-extrabold text-orange-500">79%</dt>
                                <dd class="text-orange-500 font-extrabold text-lg dark:text-orange-400"> Score</dd>
                                <dd class="text-gray-400 dark:text-gray-500">from 13 questions </dd>
                            </div>
                        </div>
                        <div className="flex flex-col items-center bg-slate-50  opacity-90 shadow-md p-5 text-center rounded-md">
                            <div class="flex flex-col items-center justify-center h-full">
                                <dt class="mb-5 text-5xl font-extrabold text-orange-500">73%</dt>
                                <dd class="text-gray-500 text-lg dark:text-gray-400">Average Score</dd>
                                <dd class="text-gray-400 dark:text-gray-500">of 13 students</dd>
                            </div>
                        </div>
                        <div className="flex flex-col items-center bg-slate-50  opacity-90 shadow-md p-5 text-center rounded-md">
                            <div class="flex flex-col items-center justify-center h-full">
                                <dt class="mb-5 text-5xl font-extrabold text-orange-500">73%</dt>
                                <dd class="text-gray-500 text-lg dark:text-gray-400">Average Score</dd>
                                <dd class="text-gray-400 dark:text-gray-500">of 13 students</dd>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="p-5 pb-20  overflow-y-auto drop-shadow-md flex-1" style={{ maxHeight: '65vh' }}>
                <Table hoverable className="">
                    <Table.Head>
                        <Table.HeadCell>
                            index
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Qestion
                        </Table.HeadCell>
                        <Table.HeadCell>
                            corrected
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Attempt
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Score
                        </Table.HeadCell>
                        <Table.HeadCell>

                        </Table.HeadCell>

                    </Table.Head>
                    <Table.Body className="divide-y">

                    </Table.Body>
                </Table>
            </div>
        </div>
    );
}
export default StudentReportView;