import config from "../config.js";
import {getAuthHeader} from "./auth_helper";


// form request with file to create a new phonic api/phonic/create
const createPhonic = async (phonic) => {
    const header = getAuthHeader();
    const formData = new FormData();
    // if tags is string, convert to array
    // if tags is array, keep it 
    const _tags = typeof phonic.tags === 'string' ? phonic.tags.split(',') : phonic.tags;
    formData.append('file', phonic.file);
    formData.append('word_ch', phonic.word_ch);
    formData.append('tags', _tags);
    formData.append('img_uri', phonic.img_uri);
    formData.append('community', phonic.community);
    const form = new FormData();
    // read  file from public folder / vreferenceTex.wav to file then form append the file
    form.append('file', window.location.origin + '/vreferenceTex.wav'); // Adjust the file path as needed
    form.append('reference_text', phonic.word_ch); // Adjust the reference text as needed
    const response = await fetch(`${config.URI}/api/phonic/create`, {
        method: 'POST',
        headers: {
            'authorization': header.Authorization
        },
        body: formData
    });
    const data = await response.json();
    return data; 
}

// get phpnic public from /api/phonic/public with page param
const getPublicPhonic = async (page) => {
    return new Promise(async (resolve, reject) => {
    const _page = page ? page : 1;
    const header = getAuthHeader();
    const response = await fetch(`${config.URI}/api/phonic/public?page=${_page}`, {
        method: 'GET',
        headers: header
    });
    console.log("response");
    console.log(response);
    const data = await response.json();
    console.log("data");
    console.log(data);
    resolve(data);
    });
}


export { createPhonic, getPublicPhonic }

const phonicService = {
    createPhonic,
    getPublicPhonic
}

export default phonicService;