import config from "../config.js";
import { getAuthHeader } from "./auth_helper";

// get assignment record 
// param assignment_id

const getAssignmentRecord = async (assignment_id) => {
    const header = getAuthHeader();
    // fect assignment record from /api/assignmentrecord/:assignment_id with header 
    const response = await fetch(`${config.URI}/api/assignmentrecord/${assignment_id}`, {
        method: 'GET',
        headers: header
    });
    const data = await response.json();
    return data;
}

// get assignment record by student id api/assignmentrecord/student/:student_id with header

const getAssignmentRecordByStudentId = async (student_id) => {
    const header = getAuthHeader();
    // fect assignment record from /api/assignmentrecord/:assignment_id with header 
    const response = await fetch(`${config.URI}/api/assignmentrecord/student/${student_id}`, {
        method: 'GET',
        headers: header
    });
    const data = await response.json();
    return data;
}

// get assignment record analyse by assignment id from /api/assignmentrecord/:assignment_id  with header
const getAssignmentRecordAnalyse = async (assignment_id) => {
    console.log(`getAssignmentRecordAnalyse ${assignment_id}`);
    try {
        const header = getAuthHeader();
        // fect assignment record from /api/assignmentrecord/:assignment_id with header 
        const response = await fetch(`${config.URI}/api/assignmentrecord/report/${assignment_id}`, {
            method: 'GET',
            headers: header
        });
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

// get assignment records by assignment id and student_id from  /api/assignmentrecord/student/{student_id}/assignment/{assignment_id}  with header
const getAssignmentRecordByStudentIdAndAssignmentId = async (student_id, assignment_id) => {
    console.log(`getAssignmentRecordByStudentIdAndAssignmentId ${student_id} ${assignment_id}`);
    try {
        const header = getAuthHeader();
        // fect assignment record from /api/assignmentrecord/:assignment_id with header
        const response = await fetch(`${config.URI}/api/assignmentrecord/student/${student_id}/assignment/${assignment_id}`, {
            method: 'GET',
            headers: header
        });
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

// PUT '/api/archive/:assignment_id/:archive' with header 
// archive assignment
const setArchiveAssignment = async (assignment_id, archive) => {
    console.log(`archiveAssignment ${assignment_id} ${archive}`);
    // fect assignment record from /api/assignmentrecord/:assignment_id with header
    const header = getAuthHeader();
    const response = await fetch(`${config.URI}/api/assignment/archive/${assignment_id}/${archive}`, {
        method: 'PUT',
        headers: header
    });
    // if response is ok return data else return null
    if (response.ok) {
        const data = await response.json();
        return data;
    }
    return null;
}


const RecordService = {
    getAssignmentRecord,
    getAssignmentRecordByStudentId,
    getAssignmentRecordAnalyse,
    getAssignmentRecordByStudentIdAndAssignmentId,
}

const Services = { 
    setArchiveAssignment
}

const AssignmnetService = {
    RecordService,
    setArchiveAssignment
}

export {
    RecordService
}
export default AssignmnetService;

