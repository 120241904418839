import react from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faArchive  ,faUserClock, faQuestionCircle, faTrophy, faClose, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux';
import { closeViewReport } from "../../../features/home/reportSlice";
import { useSelector } from "react-redux";
import {HiArchive} from "react-icons/hi";

const InfoCardBox = () => {

    const dispatch = useDispatch();
    const { assignmentRecord } = useSelector(state => state.homeReducer);
    const assignment = useSelector(state => state.homeReducer.assignments.find(assignment => assignment._id === assignmentRecord.assignment_id));

    // function on archive click
    const onArchiveClick = () => {
        console.log("archive clicked");
        // archive  = if assignment.archived is not exist or false then it will be false else true
        const archive = assignment.archived ? false : true;
        dispatch({ type: "SET_ARCHIVE_ASSIGNMENT", payload: { assignment_id: assignment._id, archived : archive } });
    }
    return (
        <>
            <div className="h-full  space-y-3 justify-between items-center flex-row w-1/3 pl-5 pr-5 pt-3  text-slate-500">
                <button onClick={() => {
                    console.log("clicked")
                    dispatch({ type : "home/setAssignmentRecord", payload : null });
                }}
                    className="rounded-lg drop-shadow-xl w-full text-white  p-1 pl-3 bg-red-500">
                    <FontAwesomeIcon icon={faClose} className="pr-3" />
                    Exit
                </button>
                <div className="rounded-lg drop-shadow-xl w-full bg-sky-50 p-1 pl-3">
                    <FontAwesomeIcon icon={faUserClock} className="pr-3" />
                    <span className=" font-bold">Due: - </span>
                </div>
                <div 
                onClick={onArchiveClick}
                className=" cursor-pointer rounded-lg hover:bg-green-700 hover:text-stone-50 text-slate-200 drop-shadow-xl w-full bg-green-500 p-1 pl-3">
                <FontAwesomeIcon icon={faArchive} className="pr-3" />
                    {  assignment.archived ? <span className=" font-bold">  Unarchive  </span> :
                    <span className=" font-bold">  Archive  </span>
                    }
                </div>
                <div className="rounded-lg drop-shadow-xl w-full bg-sky-300 p-1 pl-3 hover:bg-orange-300 hover:text-white cursor-pointer">
                    <FontAwesomeIcon icon={faQrcode} className="pr-3" />
                    <span className=" font-bold">  SHARD CODE : {assignment?.share_code}  </span>
                </div>

            </div>

        </>
    )
}

export default InfoCardBox;

