import react from 'react';
import { useDispatch } from 'react-redux';
import { setPopup, closePopup } from '../../../features/globalReducer';



// card react component with props card as a parameter
const CardQuiz = ({ card }) => {

    const dispatch = useDispatch();

    const playHandler = (index) => {
        console.log("playHandler");
        dispatch(
            setPopup(
            {
                show: true,
                title : "Phase 2",
                message : "QUIz is not implemented ",
                color : "warning"
            }
        ));

    };

    return (
        <div onClick={playHandler} className="max-w-sm rounded overflow-hidden shadow-lg hover:shadow-2xl hover:border-2 box-border border-yellow-100 cursor-pointer">
            <img className="w-full" src="https://i.pinimg.com/564x/c9/1b/64/c91b64bf9f986e3b06b9b01a0622e0bd.jpg" alt="Sunset in the mountains">
            </img>
            <div className="px-3 py-2">
                <div className="font-bold text-sm mb-2">{card.tittle}</div>

                <p className="text-gray-700 text-xs">
                    {card.lastedited}
     
                </p>

            </div>
            <div className="px-2 pt-1 pb-2">
                {card.tags.map((tag, index) => (
                    <span className="inline-block bg-gray-200 rounded-full px-1 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">#{tag}</span>
                ))}
            </div>
        </div>
    )
}

export default CardQuiz;