
import { call, put } from 'redux-saga/effects';
import { sagaActions } from './saga_actions';
import { getAuthHeader } from '../services/auth_helper';
import config from '../config';


function* fetchStudentsData (action) {
    try {
        console.log("fetchStudentsData");
        const url =  config.URI+'/api/studygroup/allstudents';
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // yield put(fetchDataSuccess(data));
        yield put({ type: sagaActions.SETSTUDENTS, payload: data });
    } catch (e) {
        console.log(e);
    }
}

function* fetchGeneratedStudents (action) {
    try {
        console.log("fetchGeneratedStudents");
        // amount from payload

        const { number, study_group_id, school}= action.payload;
        if (number < 1 || number > 30) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Number of students must be between 1 and 30", type: "error" } });
            return;
        }
        if(!study_group_id){
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Please select a group", type: "error" } });
            return;
        }
        const url =  config.URI+'/api/user/generate_students';
        const headers = getAuthHeader();
        // post with amount, study_group_id, school
        console.log({ "amount": number, "study_group_id": study_group_id, "school": school });
        const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify({ "number": number, "study_group_id": study_group_id, "school": school }) });
        // if response is not ok, call popup to show error e
        if (!response.ok) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Something went wrong", type: "error" } });
            return;
        }

        const data = yield response.json();
        console.log(data);

        // yield put({ type: sagaActions.FETCH_STUDENTS, payload: data });
        // get students by study group id
        // dispatch({ type: 'home/setCurrentStudyGroup', payload: studyGroups[index] });
        yield put({ type: 'home/setCurrentStudyGroup', payload: study_group_id });
        yield put({ type: sagaActions.FETCH_STUDENTS_BY_STUDYGROUP, payload: study_group_id });
        // call popup to show success created 
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Create new ${number} students successfully`, type: "success" } });
    } catch (e) {
        console.log(e);
    }
}

// ADD_EXIST_STUDENT_BY_CODE : POST /api/studygroup/addStudent
// body : student_code, study_group_id 
// return : student 
function* ADD_EXIST_STUDENT_BY_CODE (action) {
    try {
        console.log("ADD_EXIST_STUDENT_BY_CODE");
        // get student_code, study_group_id from payload
        const { student_code, study_group_id } = action.payload;
        const url =  config.URI+'/api/studygroup/addStudent';
        const headers = getAuthHeader();
        // post with student_code, study_group_id
        const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify({ "student_code": student_code, "study_group_id": study_group_id }) });
        // if response is not ok, call popup to show error e
        if (!response.ok) {
            // call popup to show error e
            const message = yield response.text();
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: `Fail: ${ message }` , type: "error" } });  
            return;
        }
        const data = yield response.json();
        console.log(data);
        // call popup to show success created
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Add student successfully", type: "success" } });
     
        yield put({ type: sagaActions.FETCH_STUDENTS_BY_STUDYGROUP, payload: study_group_id });
    } catch (e) {
        console.log(e);
    }
}

// update student 
function* updateStudent (action) {
    try {
        console.log("updateStudent");
        // get student_id, email, name  from payload 
        const { student_id, email, name, currentStudyGroup } = action.payload;
        const url =  config.URI+'/api/user/updatebyteacher';
        const headers = getAuthHeader();
        // post with student_id, email, name    
        const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify({ "student_id": student_id, "email": email, "name": name }) });
        if (!response.ok) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: response.error ? response.error : "Fail to Update", type: "error" } });
            return;
        }
        const data = yield response.json();
        // MESSAGE_POPUP 
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Update successfully", type: "success" } });
        console.log(data);
        // reload students
        // yield put({ type: sagaActions.FETCH_STUDENTS, payload: data });
        // FETCH_STUDENTS_BY_STUDYGROUP
        // delay wait for 1 second
        // set current study group to currentStudyGroup
        // yield put({ type: 'home/setCurrentStudyGroup', payload: currentStudyGroup });
        console.log('---------------------------');
        console.log(currentStudyGroup._id);
        console.log('---------------------------');
        yield put({ type: sagaActions.FETCH_STUDENTS_BY_STUDYGROUP, payload: currentStudyGroup._id });
    } catch (e) {
        console.log(e);
    }
}

// deleteStudent 
function* deleteStudent (action) {
    try {
        console.log("deleteStudent");
        // get student_id, study_group_id from payload
        const {student_id, currentStudyGroup} = action.payload;
        const url =  config.URI+'/api/user/'+student_id;
        const headers = getAuthHeader();
        // delete with student_id
        const response = yield call(fetch, url, { headers, method: 'DELETE' });
        if (!response.ok) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Fail to delete", type: "error" } });
            return;
        }
        const data = yield response.json();
        console.log(data);
        // MESSAGE_POPUP
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Delete successfully", type: "success" } });
        // reload students
        yield put({ type: sagaActions.FETCH_STUDENTS_BY_STUDYGROUP, payload: currentStudyGroup._id });

  
    } catch (e) {
        console.log(e);
    }
}


// get study groups by teacher 
function* fetchStudyGroups (action) {
    try {
        console.log("getStudyGroups");
        const url =  config.URI+'/api/studygroup/byteacher';
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        yield put({ type: 'home/setStudyGroups', payload: data });
    } catch (e) {
        console.log(e);
    }
}

// get study group by id 
function* getStudyGroupById (action) {
    try {
        console.log("getStudyGroupById");
        const url =  config.URI+'/api/studygroup/students';
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // emit update study groups
        // yield put({ type: sagaActions.SETSTUDYGROUPS, payload: data });
    } catch (e) {
        console.log(e);
    }
}

// get students by study group id
function* FETCH_STUDENTS_BY_STUDYGROUP (action) {
    try {
        console.log("FETCH_STUDENTS_BY_STUDYGROUP");
        const study_group_id = action.payload;
        const url =  config.URI+'/api/studygroup/students/' + study_group_id;
        const headers = getAuthHeader();
        const response = yield call(fetch, url, { headers });
        const data = yield response.json();
        console.log(data);
        // if response is not ok, call popup to show error e
        if (!response.ok) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Something went wrong", type: "error" } });
            return;
        }
        // emit update students
        yield put({ type: sagaActions.SETSTUDENTS, payload: data });
    } catch (e) {
        console.log(e);
    }
}

// removeStudentFromStudyGroup post /api/studygroup/leave body : user_id, student_group_id 
function* removeStudentFromStudyGroup (action) {
    try {
        console.log("removeStudentFromStudyGroup");
        // get user_id, student_group_id from payload
        const { user_id, student_group_id } = action.payload;
        const url =  config.URI+'/api/studygroup/leave';
        const headers = getAuthHeader();
        // post with user_id, student_group_id
        const response = yield call(fetch, url, { headers, method: 'POST', body: JSON.stringify({ "user_id": user_id, "student_group_id": student_group_id }) });
        // if response is not ok, call popup to show error e
        if (!response.ok) {
            yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Something went wrong", type: "error" } });
            return;
        }
        const data = yield response.json();
        console.log(data);
        // emit update students
    
        yield put({ type: sagaActions.FETCH_STUDENTS_BY_STUDYGROUP, payload: student_group_id });
        // call popup to show success created
        yield put({ type: sagaActions.MESSAGE_POPUP, payload: { message: "Remove student successfully", type: "success" } });
        
    } catch (e) {
        console.log(e);
    }
}

export {
    removeStudentFromStudyGroup,
    fetchStudentsData,
    fetchGeneratedStudents,
    updateStudent,
    fetchStudyGroups,
    getStudyGroupById,
    FETCH_STUDENTS_BY_STUDYGROUP,
    ADD_EXIST_STUDENT_BY_CODE,
    deleteStudent
}