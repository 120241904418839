import React, { Component } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { closeStudentReport, closeViewReport } from '../../../features/home/reportSlice'


const ReportCardName = () => {
    //   const {currentReport} = useSelector(state => state.reportReducer);
    const {assignmentRecord} = useSelector(state => state.homeReducer);
    const assignment = useSelector(state => state.homeReducer.assignments.find(assignment => assignment._id === assignmentRecord.assignment_id));

    const onExitClick = () => {
        dispatch({ type : "home/setAssignmentRecord", payload : null });
    }
      const dispatch = useDispatch();
      return (
        <div className="h-3/4 w-full  text-white">
        <nav className="flex p-3" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                        <a onClick={onExitClick}  className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            Home
                        </a>
                    </li>
                    <li>
                        <div className="flex items-center">
                            <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                            <span onClick={() => dispatch(closeStudentReport())}  className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                {assignment ? assignment.assignment_name : "no data"}
                            </span>
                        </div>
                    </li>
                    
                </ol>
            </nav>
        <span className='text-black drop-shadow-md shadow-blue-950 ml-4'> Assignment : </span> <br/>
        <span className="text-2xl font-bold  pl-5 pt-3 drop-shadow-md shadow-blue-950"> { 
        assignment?.assignment_name
        }</span>
    </div>
    )
}

export default ReportCardName


