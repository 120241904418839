import { faTrophy, faUser } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReportActivityCard = () => {
    // const {currentReport} = useSelector(state => state.reportReducer);
    const {assignmentRecord} = useSelector(state => state.homeReducer);
    const {rank} = assignmentRecord;
    // sort student reports by score
    let _students_reports = [...assignmentRecord.rank];
    _students_reports.sort((a, b) => {
        return b.score - a.score;
    });
    // get the top 3 student reports
    const top3 = assignmentRecord.rank.slice(0, 3);
    // console.log(`top3 ${top3[0].name}`);

    return (
        <div className="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-between mb-4">
                <h5 className=" text-lg font-bold leading-none text-gray-900 dark:text-white">Rank</h5>
            </div>
            <div className="flow-root">
                <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                    {top3.map((student_report, index) => {
                        return (
                            <li key={index} className="py-1 sm:py-1">
                            <div className="flex items-center space-x-1">
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                             
                                      {/* show font awesome icon trophy with gold colour if index is 0 else show with user icon */}
                                        {index === 0 ? <i className="fas fa-trophy text-yellow-400"></i> : <i className="fas fa-user text-gray-400"></i>}
                                        <FontAwesomeIcon
                                    className={index === 0 ? "text-yellow-400 mr-2" : "text-gray-400 mr-2"}
                                    icon={ index === 0  ? faTrophy : faUser}
                    
                                />
                                      
                                      {student_report.name}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                     
                                     
                                     {student_report.score >= 0 ? `${student_report.score}%` : "-"}
                                </div>
                            </div>
                        </li> 
                        )
                    })}
                </ul>
            </div>
        </div>


    );
};

export default ReportActivityCard;