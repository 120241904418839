import React from "react";
import SpeechLabNav from "./SpeechLabNav";
import SpeechLabList from "./SpeechLabList";
import SpeechLabModal from "./SpeechLabModal.jsx";
import { useSelector } from "react-redux";



const SpechLabTable = (state) => {
    
    const {showAddSpeechLabModal}  = useSelector(state => state.speechLabReducer);

    return (
        
        <div className="bg-white flex-colflex">
            <SpeechLabNav />
            <SpeechLabList/>
             { showAddSpeechLabModal && <SpeechLabModal showAddSpeechLabModal/> }
        </div>
    );
};

export default SpechLabTable;

                            