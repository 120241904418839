// components home tab 
import { useSelector, useDispatch } from 'react-redux';
import { setTab } from '../../features/home/homeSlice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatWizard ,faUsers, faChartSimple, faClipboardQuestion, faSpellCheck } from "@fortawesome/free-solid-svg-icons";

export const HomeTabs = () => {

    const {currentTab} = useSelector(state => state.homeReducer.value);
    const dispatch = useDispatch();

    const clickTapHandler = (tab) => {
        dispatch(setTab(tab));
    }

    return (
        <div className="border-b border-gray-200 dark:border-gray-700 justify-center items-center flex w-full">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className={ currentTab === 'Teachers' ? 'bg-white border-sky-300 rounded-t-lg mr-2 cursor-pointer text-indigo-400' : "mr-2 cursor-pointer hover:text-orange-400"}>
                    <span  className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg  hover:border-orange-300 ">
                    <FontAwesomeIcon icon={faHatWizard} className="w-5 h-5 mr-2 "/>
                        Teachers
                     </span>
                </li>
                <li className={ currentTab === 'students' ? 'bg-white border-sky-300 rounded-t-lg mr-2 cursor-pointer text-indigo-400' : "mr-2 cursor-pointer hover:text-orange-400"}>
                    <span onClick={() => clickTapHandler(`students`)} className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:border-orange-300 ">
                    <FontAwesomeIcon icon={faUsers} class="w-5 h-5 mr-2"/>
                    Students
                    </span>
                </li>
                <li className={ currentTab === 'speechlabs' ? 'bg-white border-sky-300 rounded-t-lg mr-2 cursor-pointer text-indigo-400' : "mr-2 cursor-pointer hover:text-orange-400"}>
                    <span onClick={() => clickTapHandler(`speechlabs`)}  className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg  hover:border-orange-300 ">
                    <FontAwesomeIcon icon={ faSpellCheck} className="w-5 h-5 mr-2 "/>
                        Speech Labs
                     </span>
                </li>
                <li className={ currentTab === 'reports' ? 'bg-white border-sky-300 rounded-t-lg mr-2 cursor-pointer text-indigo-400' : "mr-2 cursor-pointer hover:text-orange-400"}>
                    <span onClick={() => clickTapHandler(`reports`)}  className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg  hover:border-orange-300">
                    <FontAwesomeIcon icon={faChartSimple} class="w-5 h-5 mr-3"/>
                    Assignments
                   </span>
                </li>
                <li className={ currentTab === 'quizes' ? 'bg-white border-sky-300 rounded-t-lg mr-2 cursor-pointer text-indigo-400' : "mr-2 cursor-pointer hover:text-orange-400"}>
                    <span onClick={() => clickTapHandler(`quizes`)}  className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg  hover:border-orange-300 ">
                    <FontAwesomeIcon icon={faClipboardQuestion} className="w-5 h-5 mr-2 "/>
                        Quizes
                     </span>
                </li>
                
             
            </ul>
        </div>

    );
}



export default HomeTabs;