import config from "../config.js";
import {getAuthHeader} from "./auth_helper";

// GET /api/speech/textToSpeech 
// param text
// return mp3 file
const textToSpeech = async (text) => {
    const header = getAuthHeader();
    const response = await fetch(`${config.URI}/api/speech/textToSpeech?text=${text}`, {
        method: 'GET',
        headers: header
    });
    const data = await response.blob();
    return data;
}

// speech recognition
const speechRecognition = async (file, text) => {
    const header = getAuthHeader();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('reference_text', text);
    const response = await fetch(`${config.URI}/api/speech/recognition`, {
        method: 'POST',
        headers: header,
        body: formData
    });
    const data = await response.json();
    return data;
}


    


export { textToSpeech, speechRecognition}