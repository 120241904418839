import React, { useEffect, useState } from "react";
import { toggleAddStudentModal } from "../../../features/studentReducer";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, div } from 'flowbite-react';


const AddStudentModal = () => {

    const dispatch = useDispatch();
    const [state, setState] = useState({
        amount: 0,
        studyGroup: null
    });

    const studyGroups = useSelector(state => state.homeReducer.studyGroups);
    const currentStudyGroup = useSelector(state => state.homeReducer.currentStudyGroup);
    const school = useSelector(state => state.userReducer.user?.school);
    const handleChange = (e) => {
        setState({
            ...state,
            amount: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(`${state.amount} : ${state.studyGroup._id} : ${school}`);
        dispatch( {type: 'fetchGeneratedStudents', payload: {number: state.amount, study_group_id: state.studyGroup._id, school: school}});
        dispatch(toggleAddStudentModal());
    }

    const handleCancel = () => {
        console.log("Cancel");
        dispatch(toggleAddStudentModal());
    }

    const selectGroup = (index) => {
        console.log("selectGroup " + studyGroups[index]);
        setState({
            ...state,
            studyGroup: studyGroups[index]
        }); 
    }

    // on start if current study group is null, set it to the first study group


    useEffect(() => {   
        console.log("AddStudentModal useEffect");
        // state.studyGroup = studyGroups[0];
        setState({
            ...state,
            studyGroup: currentStudyGroup
        });
    }, []);

    return (
        <div className="fixed z-10 inset-0 bg-amber-50 bg-opacity-80 w-full h-full outline flex justify-center items-center">
            <div className='flex justify-center items-center flex-col gap-5 bg-white rounded-xl shadow pl-5 pr-5 pb-5'>
                <div className="relative">
                    <div className='flex-col justify-end items-end flex '>
                        <button onClick={handleCancel} type="button" className="  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="authentication-modal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="flex justify-center items-center flex-col gap-2">
                        <label for="pin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Add {state.amount} students to {state.studyGroup?.title}
                        </label>
                        <Dropdown label={(state.studyGroup) ? state.studyGroup?.title : "Select Study Group"} 
                               placement="right" >
                                 <Dropdown.Item >
                                    none
                                </Dropdown.Item>
                            {studyGroups.map((studyGroup, index ) =>      
                                <Dropdown.Item key={index} onClick={()=>{ selectGroup(index); console.log(studyGroup);}} >
                                    {studyGroup.title}
                                </Dropdown.Item>
                            )}
                        </Dropdown>
                        <input type="number"
                            value={state.amount} onChange={handleChange}
                            placeholder="how many new students" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" />
                        <button onClick={handleSubmit} className="w-full text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Add Students
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default AddStudentModal;