import { Table, Modal, Button } from 'flowbite-react';
import {  useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faQrcode, faTrash } from '@fortawesome/free-solid-svg-icons';
import ACTIONS from '../../../redux/saga_actions';
import QRCode from "react-qr-code";
import { useRef } from 'react';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { set } from 'lodash';

const StudentList = () => {
    const [height, setHeight] = useState(null);
    // get students from redux store studentReducer
    const {students} = useSelector((state) => state.homeReducer);
    // current study group
    const {currentStudyGroup} = useSelector((state) => state.homeReducer);
    const [qrpass, setQrpass] = useState("undefined");
    const [qrStudent, setQRStudents] = useState({name: "undefined", qrpass: "undefined"});
    const [pendingRemoveStudent, setPendingRemoveStudent] = useState(null);
    // let students = getStudents();
    if(students !== undefined){
         console.log(`student list ${students}`)
         console.log(students);
    }else{
        console.log(`student list is undefined`);
    }

    const [editIndex, setEditindex] = useState(-1);
    const dispatch = useDispatch();
    const  QRElement = useRef(null)
    const [openModal, setOpenModal] = useState(undefined);
    const currentEditData = {
        id: null,
        email: null,
        name: null,
        years: null,
        qrpass: null
    }
    const editData = (index) => () => {
        console.log(`edit index ${index}`);

        currentEditData.id = students[index]._id;
        currentEditData.email = students[index].email;
        currentEditData.name = students[index].name;
        currentEditData.years = students[index].years;
        setEditindex(index);
    }
    const Save = (index) => () => {
        console.log(`index ${index} saved`);
        if (
            currentEditData.email !== students[index].email ||
            currentEditData.name !== students[index].name ||
            currentEditData.years !== students[index].years
        ) {
            console.log(`change ${currentStudyGroup._id}`);
            // dispatch(updateStudentData(currentEditData));
            dispatch({type: ACTIONS.FETCH_UPDATE_STUDENT , payload: {student_id: currentEditData.id, email: currentEditData.email, name: currentEditData.name, currentStudyGroup: {...currentStudyGroup}}});
        } else {
            students[index] = currentEditData;
            console.log('no change');

        }
        setEditindex(-1);
    }
    const handleEditData = (data) => (e) => {
        currentEditData.id = students[data.index]._id;
        currentEditData.email = students[data.index].email;
        currentEditData.name = students[data.index].name;
        currentEditData.years = students[data.index].years;
        console.log(`add data ${data.target} : ${e.target.value}`);
        currentEditData[data.target] = e.target.value;


    }

    const handleShowQRCode = (student) => () => {
        console.log(`show qrcode ${qrpass}`);
        setOpenModal('default');
        // set QR code
        setQRStudents(student);
    }

    const downloadQRCode = async (name) => {
        // Generate download with use canvas and stream
        const element = document.getElementById("qr-gen");
        const canvas  = await html2canvas(element);
        const dataURL = canvas.toDataURL("image/png");
        downloadjs(dataURL, `${name}.png`, 'image/png');
      };
    
    const initDeleteStudent = (student) => () => {
        setPendingRemoveStudent(student);
        setOpenModal('pendingDeleteStudent');
    }
    const cancelPendingDeleteStudent = () => {
        setPendingRemoveStudent(null);
        setOpenModal(undefined);
    }

    const confirmRemovePendingStudnet = () => {
        dispatch({type: ACTIONS.REMOVE_STUDENT_FROM_STUDYGROUP,
            payload: {
            user_id: pendingRemoveStudent._id,
            student_group_id: currentStudyGroup._id
        }});
        setPendingRemoveStudent(null);
        setOpenModal(undefined);
    }


      useEffect(() => {
        const _height = window.innerHeight - 190;
        setHeight(_height);
    }, []);

    return (
        <div className=" flex-grow flex-1" >
            <div className="p-5 pb-20  overflow-y-auto drop-shadow-md" s style={{height: `${height}px`}}>
                <Table hoverable>
                    <Table.Head>
                        <Table.HeadCell className="!p-4">
               
                        </Table.HeadCell>
                        <Table.HeadCell>

                        </Table.HeadCell>
                        <Table.HeadCell>
                            NAME
                        </Table.HeadCell>
                        <Table.HeadCell>
                            code access
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Email || Student ID
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Assignments
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">
                                Edit
                            </span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {(students && students.length > 0) && students.map((_student, index) => (
                            <Table.Row key={_student._id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="!p-4">
                                    {editIndex !== index && 
                                        index + 1
                                    }
                                </Table.Cell>
                                <Table.Cell>

                                    {editIndex !== index && <img src="https://i.pinimg.com/736x/1e/d9/a4/1ed9a41e26348ff68736cca0362f960e.jpg" class="w-10 h-10 shadow-md rounded-full" />}

                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                   {editIndex !== index && _student?.name}
                                    {editIndex === index &&
                                        <input type="text" className="border-2 border-gray-300 p-2 w-full" onChange={handleEditData({ target: "name", index: index })} defaultValue={_student.name} />
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                {editIndex !== index && <a onClick={handleShowQRCode(_student)} className='mr-5 hover:text-sky-500 cursor-pointer'><FontAwesomeIcon icon={faQrcode} /></a>}
                                    
                                    {_student.qrpass}
                                </Table.Cell>
                                <Table.Cell>
                                    {editIndex !== index && _student.email}
                                    {editIndex === index &&
                                        <input type="text" className="border-2 border-gray-300 p-2 w-full" onChange={handleEditData({ target: "email", index: index })} defaultValue={_student.email} />
                                    }
                                </Table.Cell>
                                <Table.Cell>

                                    {_student.assignments}
                                </Table.Cell>
                                <Table.Cell>

                                    {editIndex !== index &&
                                    <div className=" gap-2 inline-flex">
                                        <a
                                            className=" cursor-pointer font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                            onClick={editData(index)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />

                                        </a>
                                        <a 
                                            onClick={initDeleteStudent(_student)}
                                        className="font-medium cursor-pointer text-red-500 px-2 hover:text-red-700 hover:underline dark:text-cyan-500"
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </a>
                                    </div>
                                    }

                                    {editIndex === index &&
                                        <a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                                            onClick={Save(index)}
                                        >
                                            <p>Save</p>
                                        </a>

                                    }
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <Modal show={openModal === 'default'} onClose={() => setOpenModal(undefined)}>
                <Modal.Header>ID : {qrStudent.name} </Modal.Header>
                <Modal.Body>
                    <div id='qr-gen' className="flex flex-col space-y-6 p-5 justify-center items-center">
                     <QRCode value={qrStudent.qrpass} />
                     name : {qrStudent.name}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => downloadQRCode(qrStudent.name)}>
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />  Download
                    </Button>
                    <Button color="gray" onClick={() => setOpenModal(undefined)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={openModal == 'pendingDeleteStudent' && pendingRemoveStudent} onClose={() => setOpenModal(undefined)}>
                <Modal.Header> 
                    <span className='text-lg font-bold text-red-500'> 
                        Are you sure you want to Remove this student { pendingRemoveStudent?.name } fron Class 
                        { currentStudyGroup?.title } ?
                        </span>
                    
                     
                      </Modal.Header>
                <Modal.Body>
                    <div id='qr-gen' className="flex flex-col space-y-6 p-5 justify-center items-center">
                        <p>
                        {/* <span className='text-sm leading-3 text-gray-400'> ID : { pendingDeleteStudent?._id} </span>   <br/> */}
                            email : {pendingRemoveStudent?.email} <br/>
                            name : {pendingRemoveStudent?.name}
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={confirmRemovePendingStudnet} >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />  
                        Remove
                    </Button>
                    <Button color="gray" onClick={cancelPendingDeleteStudent}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default StudentList;