'use client';
import { Alert } from 'flowbite-react';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { closePopupEvent } from "../features/globalReducer";
import { useDispatch } from "react-redux";
import { useEffect } from 'react';



export default function PopupMessage() {

  // effect to close popup after 5 seconds after to show but before it show cancel previous timer
  const { popupMessage, showPopupMessage } = useSelector(state => state.globalReducer);
  let timer = null;
   
  useEffect(() => {
    clearTimeout(timer);
    if (showPopupMessage) {
      timer = setTimeout(() => {
        dispatch(closePopupEvent())
      }, 3000);
    }
  }, [showPopupMessage]);


  


  const dispatch = useDispatch();

  return (
    <>
     {showPopupMessage &&
      <Alert color={popupMessage.color}  className=' fixed top-10 right-5 shadow-lg z-30'>
        <span>
          <p>
            <span className="font-medium mr-4">
              {popupMessage.title} : 
            </span>
            {popupMessage.message}

          <FontAwesomeIcon size='xl' icon={faCircleXmark} className="cursor-pointer ml-4" onClick={ ()=> {dispatch(closePopupEvent())} } />
          </p>
        </span>
      </Alert>}
    </>
    
  )
}