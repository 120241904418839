import React from "react";
import { Table, Checkbox } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';   
import { faEye, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { useState } from "react";


const ReportQuestionsTabView = () => {
    
    
    const assignmentRecord = useSelector(state => state.homeReducer.assignmentRecord);
    // loop through assignmentRecord.report key 
    const reports = [];
    for (const key in assignmentRecord.report) {
        let value = {...assignmentRecord.report[key]};
        console.log(value);
      }
    const [currentReport, setCurrentReport] = useState(null);
    return (
        <div className="p-5 pb-20  overflow-y-auto " style={{ maxHeight: '65vh' }}>
        <Table hoverable className="table-auto drop-shadow-md">
            <Table.Head>
            <Table.HeadCell>
                    index
                </Table.HeadCell>
                <Table.HeadCell className="!p-4">
                    content
                </Table.HeadCell>
                <Table.HeadCell>
                    average score
                </Table.HeadCell>
                <Table.HeadCell>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y  ">
                {Object.keys(assignmentRecord.report).map((key, index) => (
                    <Table.Row key={assignmentRecord.report[key]._id} className="bg-white  hover:bg-teal-50 hover:shadow-2xl ">
                        <Table.Cell className="!p-4">
                            {index + 1}
                        </Table.Cell>
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                            {assignmentRecord.report[key].word}
                        </Table.Cell>
                        <Table.Cell>
                        {assignmentRecord.report[key].averageScore}%
                        </Table.Cell>
                        <Table.Cell
                            className=" hover:text-lime-500 cursor-pointer">
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            view
                        </Table.Cell>
                    </Table.Row>
                ))}
                {
                  
                    Object.keys(assignmentRecord.report).length === 0 &&
                 
                    assignmentRecord.speechLab.phonics.map((phonic, index) => (
                        <Table.Row key={phonic._id} className="bg-white  hover:bg-teal-50 hover:shadow-2xl ">
                            <Table.Cell className="!p-4">
                                {index + 1}
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {phonic.word_ch}
                            </Table.Cell>
                            <Table.Cell>
                                -
                            </Table.Cell>
                            <Table.Cell
                                className=" hover:text-lime-500 cursor-pointer">
                                <FontAwesomeIcon icon={faEye} className="mr-2" />
                                view
                            </Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>
    </div>
    )
};

export default ReportQuestionsTabView;