import React  from "react";
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
import CardQuiz from "./CardQuiz";
import QuizControlBar from "./QuizControlBar";


const ReportView = () => {

    // use state to store cards
    const [cards, setCards] = useState([]);
    const [height, setHeight] = useState(null);
    useEffect(() => {
        // generate 20 students 
        const _cards = [];
        for (let i = 0; i < 10; i++) {
            _cards.push({
                id: faker.string.uuid(),
                tittle: faker.internet.displayName(),
                describe: faker.lorem.lines(3),
                tags: [
                    faker.lorem.word(),
                    faker.lorem.word(),
                ],
                lastedited: faker.date.recent().toDateString(),
            });
        }
        setCards(_cards);

        console.log(..._cards);
    }, []);


    useEffect(() => {
        const _height = window.innerHeight - 190;
        setHeight(_height);
    }, []);



    return (
        <div className="bg-white flex flex-col">
            <QuizControlBar />
            <div className=" flex-grow flex-1" >
            <div className="overflow-y-scroll" style={{height: `${height}px`}} >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5  pb-20">
                    {cards.map((card, index) => (
                        <CardQuiz key={card.id} card={card} />
                    ))}
                </div>
                </div>
            </div>
        </div>
    );
};


export default ReportView;