import React from "react";
import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faSearch,faFilter} from "@fortawesome/free-solid-svg-icons";
import {toggleAddSpeechLabModal} from "../../../features/home/speechLabSlice";
import { useDispatch } from "react-redux";


const SpeechLabNav = () => {

    const dispatch = useDispatch();

    const handleClikcAdd = () => {
        console.log("add");
        dispatch(toggleAddSpeechLabModal());
    };
    return (
        <div className="flex justify-between items-center flex-row pt-2 pl-5 pr-5 pb-2">
            <Button onClick={handleClikcAdd} color="primary" rounded className="hover:text-sky-500 mr-5">
                <FontAwesomeIcon icon={faCirclePlus} size="xl" className="pr-3" />
                <span className=" font-bold">Add Speech Labs</span>
            </Button>
            <div className="flex justify-between items-center flex-row ">
                <Button color="primary" rounded className="hover:text-sky-500 mr-5">
                    <FontAwesomeIcon icon={faFilter} size="xl" className="" />
                </Button>
                <label className="relative inline">
                    <span className="sr-only">Search</span>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  
                    </span>
                    <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search" />
                </label>
            </div>
        </div>
    );
};

export default SpeechLabNav;