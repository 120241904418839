import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import ReportDashboardView from "../ReportDashboardView";
import StudentReportView from "../StudentReportView";


const ReportInfoView = () => {

    // listen to the redux store reportReducer state
    // const currentStudentReport = useSelector(state => state.reportReducer.currentStudentReport);
    const {assignmentRecord} = useSelector(state => state.homeReducer);

    const dispatch = useDispatch();
    return (
        <div className="w-full h-full fixed z-10 top-0 left-0 flex justify-center items-end bg-gray-600 bg-opacity-50">
            {assignmentRecord !== null ? <ReportDashboardView /> : <StudentReportView/> }
        </div>
    );
};

export default ReportInfoView;