import { Navbar, Dropdown, Avatar } from 'flowbite-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../features/user/user_slice';
import { useSelector } from 'react-redux';
    

export default function NavBar() {

    // use state to update the active link in the navbar based on the current path name 
    const [activeLink, setActiveLink] = useState(window.location.pathname);
    let path = window.location.pathname;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.user);
    // on click of the navbar links, update the active link
    const handleLinkClick = (link) => () => {
        if (link === path) return;
        path = link;
        navigate(link);
        setActiveLink(link);
        // react-router-dom trigger navigation 
    
        console.log(`${link} : activeLink: ${activeLink}`);
    };

    const handleLogout = () => {
        console.log("logout");
        dispatch( LOGOUT() );
        navigate('/');
    }

    return (

        <Navbar
            fluid
            rounded
            fixed="top"
        >
            <Navbar.Brand
            onClick={handleLinkClick('/home')}
            className=' cursor-pointer'>
                <img
                    alt="Flowbite React Logo"
                    className="mr-3 h-6 sm:h-9"
                    src="https://s3-ap-southeast-1.amazonaws.com/rgk-images/17tts_head_maxi_lc.png"
                />
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                     <span className=' font-extrabold'>QUIPPY </span> <span className=' font-extralight text-xs'>by galaxykids</span> 
                </span>
            </Navbar.Brand>
            <div className="flex md:order-2">
                <Dropdown
                    inline
                    label=
                    {
                        <span> {user?.name == null || "none" ? user?.email || "-" : user.name}</span>
                    }
                >
                    <Dropdown.Header>
                        <span className="block text-sm">
                           {user?.role}: {user?.name}
                        </span>
                        <span className="block truncate text-sm font-medium">
                            {user?.email}
                        </span>
                    </Dropdown.Header>
                   
                    <Dropdown.Item>
                        Settings
                    </Dropdown.Item>
                    <Dropdown.Item>
                        Support
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleLogout}>
                        Sign out
                    </Dropdown.Item>
                </Dropdown>
                <Navbar.Toggle />
            </div>
            <Navbar.Collapse>
                <Navbar.Link active={activeLink === '/home'} style={{cursor: activeLink !== '/home' ? "pointer" : "default" }} onClick={handleLinkClick('/home')} >
                    Home
                </Navbar.Link>
                <Navbar.Link onClick={
                    () => {
                        // open new websit  https://speechlab.galaxykids.ai in new tab
                        window.open('https://master.d3qj3949cio00w.amplifyapp.com', '_blank');
                    }
                } >
                   Dashboard
                </Navbar.Link>
                {/* <Navbar.Link active={activeLink === '/speechlab'} style={{cursor: activeLink !== '/home' ? "pointer" : "default" }} onClick={handleLinkClick('/speechlab')} >
                   FAQ
                </Navbar.Link> */}
                <Navbar.Link active={activeLink === '/wiki'} style={{cursor: activeLink !== '/home' ? "pointer" : "default" }} onClick={handleLinkClick('/wiki')} >
                    WIKI 
                </Navbar.Link>
                <Navbar.Link active={activeLink === '/support'} style={{cursor: activeLink !== '/home' ? "pointer" : "default" }} onClick={handleLinkClick('/support')} >
                    Contact Us
                </Navbar.Link>
            </Navbar.Collapse>
        </Navbar>

    );
}
