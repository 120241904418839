import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import authApi from '../services/auth_service';
import { LOGGIN_SUCCEEDED, LOGGIN_FAILED } from '../features/user/user_slice';
import {setPopup, closePopupEvent} from '../features/globalReducer';
import { delay } from 'redux-saga/effects';

let isLogin = false;
// worker Saga: will be fired on USER_FETCH_REQUESTED actions

export function* fetchUser(action) {
    try {
        console.log("Login User");
        const { email, password } = action.payload;
        // http post to /api/login with json body {email: email, password: password}
        const user = yield call(authApi.login, action.payload);
        if(user === null) { 
            yield put(LOGGIN_FAILED());
            yield put(setPopup({title: "Login Failed", message: `Please check your email and password`, color: "red"}));
            return;
        }
        console.log(`login success with ${user.email}`);
        // call success action here 
        yield put(LOGGIN_SUCCEEDED(user));
        yield put(setPopup({title: "Login Success", message: `Welcome ${user.email}`, color: "green"}));
    } catch (e) {
        yield put({ type: 'USER_LOGGIN_FAILED', message: e.message });
        yield put(LOGGIN_FAILED( e.message));

    }
}

export function* fetchUserByTokenInLocalStore(action) {
    if (isLogin === false) {
        try {

            // check if user in userreducer is null
            // if not null, return

            console.log("Login User token");
            const token = localStorage.getItem("token");
            // if token is null, return
            if (token === null) {
                yield put(LOGGIN_FAILED());
                return;
            }
            // http post to /api/login with json body {token: token}
            const user = yield call(authApi.token_login, token);
            console.log(`token login success with ${user.email}`);
            // call success action here
            yield put(LOGGIN_SUCCEEDED(user));
        } catch (e) {
            yield put({ type: 'USER_LOGGIN_FAILED', message: e.message });
            yield put(LOGGIN_FAILED( e.message));
        }
    }
}

export function* fetchUserWithPinCode(action) {
    try {
      
        const { code } = action.payload;
        console.log(`Login with code ${code}`);
        // http post to /api/login with json body {email: email, password: password}
        const user = yield call(authApi.qr_login, code);
        if(user === null) {
            yield put(LOGGIN_FAILED());
            return;
        }
        console.log(`login success with ${user.email}`);
        // call success action here
        yield put(LOGGIN_SUCCEEDED(user));
    } catch (e) {
        // yield put({ type: 'USER_LOGGIN_FAILED', message: e.message });
        yield put(LOGGIN_FAILED( e.message));

    }
}

export function* onLoginSucceeded(action) {
    isLogin = true;
            // popup login success
    yield put(setPopup({title: "Login Success",
     message: `Welcome ${(action.payload.name && action.payload.name != 'none') ? action.payload.name : action.payload.email}`
     , color: "green"}));
    // emit event location change to home
    yield put({ type: 'main/setLocation', payload: "home" });
    yield put({ type: 'FETCH_STUDYGROUPS' });
    // yield put({ type: 'fetchStudentsData' });
    yield put({ type: 'FETCH_PHONICGROUP' });
    yield put({ type: 'FETCH_ASSIGNMENTS' });
    yield put({ type: 'home/setCurrentStudyGroup', payload: null });
    
}

export function* createNewUser(action) {
    try {
        console.log("Create New User");
        console.log(action.payload);
        const body = action.payload;
        console.log(`creare with email: ${body.email}, password: ${body.password}, branch: ${body.branch.code}`);
        // http post to /api/login with json body {email: email, password: password}
        const response = yield call(authApi.register, { email: body.email, password: body.password, branch: body.branch});
        // if response status is not 200, 
        console.log("response");
        console.log(response.status);
        if (response.status !== 201) {
            // call failed action
            yield put(LOGGIN_FAILED());
            // popup login failed
            yield put(setPopup({title: "Register Failed", message: `Please check your email and password`, color: "red"}));
            return;
        }
        const user = yield response.data;
        console.log(`login success with ${user.email}`);
        // call success action here
        yield put(LOGGIN_SUCCEEDED(user));
        // message success
        yield put(setPopup({title: "Register Success", message: `Welcome ${user.email}`, color: "green"}));
        //delay 4 seconds
        yield call(delay, 4000);
        // close popup
        yield put(closePopupEvent());
    } catch (e) {
        // yield put({ type: 'USER_LOGGIN_FAILED', message: e.message });
        yield put(LOGGIN_FAILED( e.message));

    }
}

export function* onFailedLogin(action) {
    // go to login page
    console.log("Login Failed")
    yield put(setPopup({title: "Login Failed", message: "" , color: "red"}));
    
}
