import { createSlice } from "@reduxjs/toolkit";
import studyGroupApi from "../services/study_group_service";
import { useDispatch } from "react-redux";


export const studentSlice = createSlice({
    name: "student",
    initialState: {
        students: [],
        showAddStudentModal: false,
        studentAmountToAdd: 0
    },

    reducers: {
        setStudents : async (state, action) => {
            console.log(`set students ${action.payload.length}`) 
            console.log(action.payload);
            state.students = [...action.payload];
        },
        getStudents : async (state, action) => {
            // return with payload is array of students
            console.log(`get students ** ${action.payload.length}`)
            console.log(action.payload);
            // state.students = [...action.payload];
            // action.payload = state.students;
        },
        addStudent: (state, action) => {
            state.students.push(action.payload);
        },
        updateStudentData: (state, action) => {
  
            const { id, name, years, email } = action.payload;
            console.log(`update student data ${id}`);
            const student = state.students.find((student) => student.id === id);
            if (student) {
                console.log(`updateStudentData: ${id}`);
                student.name = name;
                student.years = years;
                student.email = email;
            }            
        },
        toggleAddStudentModal: (state, action) => {
            state.showAddStudentModal = !state.showAddStudentModal;
            console.log(`toggleAddStudentModal: ${state.showAddStudentModal}`);
        },
        setStudentAmountToAdd: (state, action) => {
            state.studentAmountToAdd = action.payload;
        },
        GET_STUDENTS: (state, action) => {
            // event get study group 
            // console.log(`get students by teach id `)
            // studyGroupApi.getAllStudents().then((res) => {
            //     console.log(`get students by teach id ${res.data.length}`)
            //     console.log(res.data);
            //     if(res.status === 200 && res.data.length > 0) {
            //         const _students = [...res.data];
            //         // state.students = _students;
       
            //         // dispatch({type: 'student/setStudents', payload: _students});
            //     }
            // });
        }
    }
});
export const selectStudents = studentSlice.reducer.students;
export const { addStudent, toggleAddStudentModal, setStudentAmountToAdd, updateStudentData,setStudents, GET_STUDENTS, } = studentSlice.actions;
export default studentSlice.reducer;
