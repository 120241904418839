import config from "../config.js";
import {getAuthHeader} from "./auth_helper";
import axios from "axios";
import { useSelector } from 'react-redux';

async function getStudyGroups(user) {
    return  new Promise((resolve, reject) => { 
    const url =  config.URI+'/api/studygroup/byteacher';
    const headers = getAuthHeader();
    axios.get(url, { headers })
      .then(response => {
        console.log(response.data);
        resolve(response.data);
      })
      .catch(error => {
        console.error(error);
        return null;
      });
    });
}

// get all student from all groups 
async function getAllStudents(state) {
    const headers = getAuthHeader();
    const response = await axios.get(`${config.URI}/api/studygroup/allstudents`, { headers });
    if(response.status === 200) {
        return response.data;
    }
}


// get student from group

// add student to group 

// create group 

// update student


export default {
    getStudyGroups,
    getAllStudents
}