import React, { Component } from 'react'
import { HiPhotograph } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { HiXCircle, HiSearch, HiArrowLeft, HiArrowRight} from 'react-icons/hi';
import { searchPhoto } from '../../../services/photo_service';

const NewPhonicForm = (props) => {

    const { newPhonicForm, setNewPhonicForm } = props;
    const dispatch = useDispatch();
    const [imgUri, setImgUri] = useState(null);
    const fileInputRef = React.createRef();
    const [showPhotoLibrary, setShowPhotoLibrary] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [page, setPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [community, setCommunity] = useState(false);



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setNewPhonicForm({ ...newPhonicForm, img_uri: e.target.result });

            }
            reader.readAsDataURL(file);
        }
    }

    const onPhonicSave = () => {
        console.log(`save phonic`);
        console.log(newPhonicForm);
        // dispath saga create phonic evrnt 
        // remove all space in tags
        // set tags to array by and  spliting string by comma
        // if file is not null then set file to newPhonicForm.file
        
        newPhonicForm.file = fileInputRef.current.files[0];
        if (newPhonicForm.img_uri != null){
            // remove key newPhonicForm.file from newPhonicForm
            delete newPhonicForm.file;
        }

        // remove all space in tags
        // them make tags to array by and  spliting string by comma
        const _tags = newPhonicForm.tags.replace(/\s/g, '').split(',');
        setNewPhonicForm({ ...newPhonicForm, tags: _tags});
        dispatch({ type: "CREATE_PHONIC", payload: { ...newPhonicForm } });
        setNewPhonicForm(null);
    }

    const onFinishInputWord = async () => {
        console.log(`onFinishInputWord ${newPhonicForm.word_ch}`);
        // dispath saga create phonic event
        if(!newPhonicForm.word_ch) return;
        const data = await searchPhoto(newPhonicForm.word_ch, page);
        const randomIndex = Math.floor(Math.random() * data.photos.length);
        // set newPhonicForm.img_uri to data.photos[randomIndex].src.tiny
        setNewPhonicForm({ ...newPhonicForm, img_uri: data.photos[randomIndex].src.tiny });
        setPhotos([...data.photos]);
        setSearchKey(newPhonicForm.word_ch);
    }

    const setShowPhotoLibraryHandle = (value) => {
        setShowPhotoLibrary(value);
        if(value){
            setPhotos( [...photos]);
        }
    }

    const onChangePage = (value) => {
        setPage(value);
        // onFinishInputWord();
        onSearchPhoto();
    }

    const onSearchPhoto = async () => {
        const data = await searchPhoto(searchKey, page);
        setPhotos([...data.photos]);
    }

    return (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-30 flex justify-center items-center p-5">
            <div class="max-w-sm min-w-min flex flex-col items-center p-5 bg-slate-100 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className='mb-2'>
                    <span className='text-2xl font-bold text-gray-900 dark:text-white'>New Word</span>
                </div>
                <div onClick={() => fileInputRef.current.click()}
                    className="relative h-32 aspect-[1/1] bg-slate-400 rounded-md hover:bg-cyan-300 cursor-pointer shadow-md">
                    {newPhonicForm?.img_uri && <img className="absolute w-full h-full object-cover rounded-md" src={newPhonicForm?.img_uri} alt="" />}
                    {!newPhonicForm?.img_uri && <div className="absolute w-full h-full flex justify-center items-center flex-col">
                        <HiPhotograph className="text-white text-6xl" />
                        <span className='text-white text-xs font-extralight'>Upload Image</span>
                    </div>}
                </div>
                <div className=" w-full mt-2 text-center">
                    <span
                        onClick={()=>{ setShowPhotoLibraryHandle(!showPhotoLibrary)}}
                        className='text-sm font-medium text-gray-900 dark:text-white hover:text-blue-400 cursor-pointer'>
                        photo library
                    </span>
                </div>
                <input type="file" accept="image/*" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
                <div className=" w-full mt-2">
                    <label
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        word
                    </label>
                    <input type="text"
                        value={newPhonicForm?.word_ch}
                        onChange={(e) => setNewPhonicForm({ ...newPhonicForm, word_ch: e.target.value })}
                        placeholder='chinese word' 
                        onBlur={()=>{onFinishInputWord()}}
                        className=" w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    </input>
                </div>
                <div className=" mt-2 w-full">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        tag(s)
                    </label>
                    <input type="text"
                        value={newPhonicForm?.tags}
                        onChange={(e) => setNewPhonicForm({ ...newPhonicForm, tags: e.target.value })}
                        placeholder='seperate tags with comma'
                        className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    </input>
                </div>
                <div className="flex items-center mb-4 mt-2">
                    <input id="default-checkbox" type="checkbox"
                        onChange={(e) => setNewPhonicForm({ ...newPhonicForm, community: e.target.checked })}
                        value={newPhonicForm?.community}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                    </input>
                    <label for="default-checkbox" class="ml-2 text-sm font-light text-gray-500 dark:text-gray-300">Share this content with community</label>
                </div>
                <button
                    type="button"
                    onClick={() => onPhonicSave()}
                    className="w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 ">
                    SAVE
                </button>
                <button
                    onClick={() => setNewPhonicForm(null)}
                    type="button" className=" w-full text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">
                    CANCEL
                </button>
            </div>
            {  showPhotoLibrary &&
            <div className='flex flex-col items-center justify-center'>
                <div className='text-2xl font-bold text text-white mb-5 w-full flex flex-row justify-between'>
                    <span className='pl-5'> Photo Library</span>
                    <span className='pt-2 pr-2 '> 
                        <HiXCircle 
                            onClick={()=>{ setShowPhotoLibrary(!showPhotoLibrary)}}
                            className='text-2xl text-white cursor-pointer hover:text-red-600' />
                    </span>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-1 p-2 rounded-lg ml-5 bg-slate-50">
                    { 
                         photos.map((photo, index) => {
                            return (
                                <div key={index}>
                                    <img 
                                    onClick={()=>{setNewPhonicForm({ ...newPhonicForm, img_uri: photo.src.tiny }); setShowPhotoLibrary(!showPhotoLibrary)}}
                                    className="hover:opacity-75 cursor-pointer h-32 aspect-[1/1] rounded-lg" src={photo.src.tiny} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex flex-row justify-between w-full ml-5 px-2'>
                    <div className='flex flex-row justify-between w-3/5 gap-2 bg-slate-100 p-2 mt-2 rounded-lg '>
                        <input 
                            value={searchKey}
                            onChange={(e)=>{setSearchKey(e.target.value)}}
                            type="text" placeholder='Seach Photo [ENG]' className=" w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        <button 
                            onClick={()=>{onSearchPhoto()}}
                            className='bg-blue-500 text-white rounded-lg px-2 py-1'>
                            <HiSearch className='text-white text-xl mx-1' />
                        </button>
                    </div>
                    <div className=' ml-2 flex flex-row justify-between w-2/5 gap-2 bg-slate-100 p-2 mt-2 rounded-lg '>
                        <button 
                            onClick={()=>{onChangePage(page-1)}}
                            className={
                                (page <= 1) ? 
                                'bg-gray-500 text-white rounded-lg px-2 py-1' : 
                                'bg-blue-500 text-white rounded-lg px-2 py-1'
                                } disabled={page <= 1 }>
                                <HiArrowLeft className='text-white text-xl mx-1' />
                        </button>
                        <span className=' text-lg font-bold text-gray-900 pt-2 dark:text-white hover:text-blue-400 cursor-pointer'>
                            {page}
                        </span>
                        <button 
                            onClick={()=>{onChangePage(page+1)}}
                            className='bg-blue-500 text-white rounded-lg px-2 py-1'>
                            <HiArrowRight className='text-white text-xl mx-1' />
                        </button>
                    </div>
                </div>
            </div>
            }


        </div>
    )
}

export default NewPhonicForm;