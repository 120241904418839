// Reducers and actions for the report 
// -----------------------------------------------------------------------------
import { createSlice } from '@reduxjs/toolkit';
import sampleReports from '../../reportSampleData.js';
// The initial state of the report
// const _reports = [];
// for (let i = 0; i < 10; i++) {
//     // random number of students
//     const studentNumber = faker.number.int(4, 10);
//     const _students = [];
//     for (let j = 0; j < studentNumber; j++) {

//         const questionNumber = faker.number.int(5, 10);

//         // loop make question reports 

//         const _question_reports = [];
//         for (let k = 0; k < questionNumber; k++) {
//             _question_reports.push({
//                 id: faker.string.uuid(),
//                 question: faker.lorem.sentence(),
//                 correct: false,
//                 attempts: faker.number.int(1, 5),
//                 student_answer: faker.number.int(0, 4),
//                 correct_answer: faker.number.int(0, 4),
//                 answers : [],
                
//             });
//             // add 4 fake answers 
//             for (let l = 0; l < 4; l++) {
//                 _question_reports[k].answers.push({
//                     index : l,
//                     answer: faker.lorem.sentence(),
//                     correct: false
//                 });
//                 if(l === _question_reports.correct_answer){
//                     _question_reports[k].answers[l].correct = true;
//                 }
//             }
//         }

//         _students.push({
//             id: faker.string.uuid(),
//             quiz_id: faker.string.uuid(),
//             report_id: faker.string.uuid(),
//             name: faker.internet.userName(),
//             years: faker.number.int(2),
//             email: faker.internet.email(),
//             corrected_amount :faker.number.int(1, questionNumber),
//             attempt_count : faker.number.int(1, 5),
//             question_reports : _question_reports,
//         });
//     }

//     _reports.push({
//         id: faker.string.uuid(),
//         tittle: faker.internet.displayName(),
//         students_completed : faker.number.int(1),
//         mode: "speech lab or quiz",
//         created: faker.date.recent().toDateString(),
//         due_date: faker.date.recent().toDateString(),
//         student_reports : _students
//     });
// }

// data structure for the report
// report : {
//     id : string,
//     tittle : string,
//     quiz_id : string,
//     students_completed : number,
//     mode : string,
//     created : string,
//     due_date : string,
//     student_reports : [
//         {    
//             id : string,
//             student_id : string,
//             quiz_id : string,
//             report_id : string,
//             name : string,
//             years : number,
//             email : string,
//             corrected_amount : number,
//             attempt_count : number,
//             question_reports : [
//                 {
//                     id : string,
//                     question : string,
//                     correct : boolean,
//                     attempts : number,
//                     student_answer : number,
//                     correct_answer : number,
//                     answers : [
//                         {
//                             index : number,
//                             answer : string,
//                             correct : boolean
//                         }
//                     ]
//                 }
//             ]
//         }
//     ]
// }

export const reportSlice = createSlice({
    name: 'report',
    initialState: {
        currentReport: null,
        currentStudentReport: null,
        currentReportData: {
            average_score: -1,
            complete_rate: -1,
            student_complated_count : -1,
        },
        reports : sampleReports,
        reportViewPage : 'dashbard', // dashboard, student, questions summary
        reportViewSubPage: 'summary',
        assignment_record : {
            averageScore : -1,
            rank : [],
            report : [],
        },
    },
    reducers: {
        viewReport: async (state, action) => {

            const record = action.payload;
            state.assignment_record = record;
        },
        setReports: (state, action) => {
            const { reports } = action.payload;
            state.reports = reports;
            console.log(`set reports ${reports}`)
        },
        closeViewReport: (state, action) => {
            state.currentReport = null;
            console.log(`close view report`)
        },
        OpenSubPage: (state, action) => {
            const subPage  = action.payload;
            state.reportViewSubPage = subPage;
            console.log(`open sub page ${subPage}`)
        },
        openStudentReport: (state, action) => {
            const studentAssignmentReport = action.payload;
            console.log(`openStudentReport ${studentAssignmentReport} `);
            state.currentStudentReport = studentAssignmentReport;
   
        },
        closeStudentReport: (state, action) => {   
            state.currentStudentReport = null;
            console.log(`close student report`)
            
        },
        getClassAverageScore: (state, action) => {
            let total = 0;
            if(state.currentReport !== null){
                const { student_reports } = state.currentReport;
                student_reports.forEach(student_report => {
                    total += student_report.score;
                });
                console.log(`total : ${total}`)
                console.log(`class average score ${total / student_reports.length}`)
                // returm average score percentage
                return total / student_reports.length;
            }
        },
        initReportData: (state, action) => {
            const { currentReport } = state;
            let total = 0;
            if(currentReport !== null){
                const { student_reports } = currentReport;
                student_reports.forEach(student_report => {
                    total += student_report.score;
                });
                console.log(`total : ${total}`)
                console.log(`class average score ${total / student_reports.length}`)
                state.currentReportDataaverage_score = total / student_reports.length;
            }
            // currentReportData.average_score = -1;
            // currentReportData.complete_rate = -1;
            // currentReportData.activities = [];
        }
    }
});

// Action creators are generated for each case reducer function
export const { viewReport, setReports, closeViewReport, OpenSubPage,closeStudentReport, openStudentReport, getClassAverageScore } = reportSlice.actions;
export default reportSlice.reducer;
