import React, { Component } from 'react'
import { HiSpeakerphone } from 'react-icons/hi';
import { textToSpeech } from '../services/speech_service';


export default class PhonicCard extends Component {
    // get setCurrentPhonicWord from props
    constructor(props) {
        super(props);
        this.state = {
            currentPhonic: props.currentPhonic,
            setCurrentPhonic: props.setCurrentPhonic
        }
    }  

    // on play sound click
    onPlaySoundClick = async () => {
        // play sound
        // this.state.currentPhonic.playSound();
        console.log(this.state.currentPhonic.word_ch);
        const sound = await textToSpeech(this.state.currentPhonic.word_ch);
        const audioUrl = URL.createObjectURL(sound);
        const audioElement = new Audio(audioUrl);
        audioElement.play();
    }

    render(state) {
        return (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-30 flex justify-center items-center p-5">
                <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <a href="#">
                        <img class="rounded-t-lg" src={ this.state.currentPhonic?.img_uri ? this.state.currentPhonic.img_uri : `https://s3-ap-southeast-1.amazonaws.com/rgk-images/0 video - shape song c 512x384.jpg`} alt="" />
                    </a>
                    <div class="p-5 text-center">
                        <a href="#">
                            <h5 class="mb-2 pb-7 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{this.state.currentPhonic.word_ch}</h5>
                        </a>
                        {/* <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">.</p> */}
                        <a onClick={()=>this.onPlaySoundClick()} class="inline-flex items-center px-3 py-2 text-sm font-medium cursor-pointer text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            PLAY SOUND
                            <HiSpeakerphone className="ml-2 text-xl" />
                        </a>
                        <a href="#"
                            onClick={() => this.state.setCurrentPhonic(null)}
                            class="ml-5 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            ClOSE
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
