import React, { useEffect, useState } from "react";
import { Table, Checkbox } from 'flowbite-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUnlock, faLock, faArchive } from '@fortawesome/free-solid-svg-icons';

import { useSelector, useDispatch } from 'react-redux';
import { HiQrcode} from 'react-icons/hi';
import QRDisplay from "../../QRcodePopup";


const ReportTable = (state) => {

    // useState to store report

    // get showArchived from props
    const { showArchived } = state;


    // redux reports slice 
    const phonicGroups = useSelector(state => state.homeReducer.phonicGroups);
    const studyGroups = useSelector(state => state.homeReducer.studyGroups);
    const assignments = useSelector(state => state.homeReducer.assignments);
    const currentStudyGroup = useSelector(state => state.homeReducer.currentStudyGroup);

    

    const [isFilter, setIsFilter] = React.useState(false);

    const dispatch = useDispatch();

    const handleViewReport = (assignment) => () => {
        console.log(`report.id: ${assignment._id}`);

        // dispatch saga FETCH_ASSIGNMENT_RECORED with assignment id
        dispatch({ type: 'FETCH_ASSIGNMENT_RECORED', payload: assignment._id });
        console.log(assignment)
        // dispatch(viewReport({
        //     _id: assignment._id,
        // }));
    };
    const currentDate = new Date();


    const checkDueDate = (report) => {
        if (report.due_date === null) return false;
        const due_date = new Date(report.due_date);
        return due_date <= currentDate ? true : false;
    };

    const getStudyGroupFromId = (_id) => {
        // find study group from _id
        const studyGroup = studyGroups.find((studyGroup) => studyGroup._id === _id);
        if (studyGroup === undefined) return "";
        return studyGroup.title;
    };
    // find phonic group from _id
    const getPhonicGroupFromId = (_id) => {
        const phonicGroup = phonicGroups.find((phonicGroup) => phonicGroup._id === _id);
        if (phonicGroup === undefined) return "";
        return phonicGroup.title;
    };

    // use effect currentStudyGroup  data change update ui 
    useEffect(() => {
        console.log("currentStudyGroup change");
        isFilter ? setIsFilter(false) : setIsFilter(true);
        console.log(currentStudyGroup);
    }, [currentStudyGroup]);

    const [showQR, setShowQR] = React.useState(false);
    const [qrData, setQRData] = React.useState({
        data: "",
        name: ""
    });

    const handleShowQR = (data, name) => {
        setQRData({
            data: data,
            name: name
        });
        setShowQR(true);
    }

    const [height, setHeight] = useState(null);
    useEffect(() => {
        const _height = window.innerHeight - 190;
        setHeight(_height);
    }, []);

    return (
        <div className=" flex-grow flex-1" >
            <div className="p-5 pb-40  overflow-y-auto " style={{height: `${height}px`}} >
                <Table hoverable className="table-auto drop-shadow-md">
                    <Table.Head>
                        <Table.HeadCell className="!p-4">
                            CODE
                        </Table.HeadCell>
                        <Table.HeadCell>
                            ASSIGNMENT
                        </Table.HeadCell>
                        <Table.HeadCell>
                            TASK
                        </Table.HeadCell>
                        <Table.HeadCell>
                            CLASS
                        </Table.HeadCell>
                        <Table.HeadCell>
                            Status
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <span className="sr-only">
                                {isFilter ? "Unlocked" : "Locked"}
                            </span>
                        </Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y  ">
                        {assignments.map((assignment, index) => (
                            // show if study group is null or study group id is equal to current study group id
                            (
                          
                                assignment.study_group_id === currentStudyGroup._id) &&
                            assignment.archived == false &&
                            <Table.Row key={assignment._id} className="bg-white  hover:bg-teal-50 hover:shadow-2xl ">
                                <Table.Cell className="!p-4" onClick={()=> handleShowQR(assignment.share_code,assignment.assignment_name )} >   
                                <span className="flex flex-row hover:text-blue-600 cursor-pointer">           
                                    <HiQrcode className="mr-2  text-3xl" />
                                    <span className="pt-1" >{assignment.share_code}</span> 
                                    </span> 
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {assignment.assignment_name} <br />
                                    <span className=" font-extralight text-gray-400"> {assignment.assignment_description} </span>
                                </Table.Cell>
                                <Table.Cell>
                                    {getPhonicGroupFromId(assignment.phonic_group_id)}
                                </Table.Cell>
                                <Table.Cell>
                                    {getStudyGroupFromId(assignment.study_group_id)}
                                </Table.Cell>
                                <Table.Cell className=" gap-2">
                                    {assignment.archived ?
                                        <>
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={faArchive}
                                                style={{ color: "gray" }}
                                            />
                                            <span className="text-xs text-gray-400"> Archived </span>
                                        </>
                                        : <>
                                            <FontAwesomeIcon
                                                className="mr-2"
                                                icon={checkDueDate(assignment) ? faLock : faUnlock}
                                                // size="2x"
                                                style={{ color: checkDueDate(assignment) ? "gray" : "green" }}
                                            />
                                            {assignment.due_date ?
                                                <span className="text-xs text-gray-400"> {assignment.due_date} </span> :
                                                <span className="text-xs text-gray-400"> No Due Date </span>
                                            }
                                        </>}
                                </Table.Cell>
                                <Table.Cell
                                    onClick={handleViewReport(assignment)}
                                    className=" hover:text-lime-500 cursor-pointer">
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                    view
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        {/* archived */}
                        { showArchived && assignments.map((assignment, index) => (
                            // show if study group is null or study group id is equal to current study group id
                            (
                            assignment.study_group_id === currentStudyGroup._id) &&
                            assignment.archived === true &&
                            <Table.Row key={assignment._id} className=" bg-gray-100  hover:bg-teal-50 hover:shadow-2xl ">
                              <Table.Cell className="!p-4" onClick={()=> handleShowQR(assignment.share_code,assignment.assignment_name )} >   
                                <span className="flex flex-row hover:text-blue-600 cursor-pointer">           
                                    <HiQrcode className="mr-2  text-3xl" />
                                    <span className="pt-1" >{assignment.share_code}</span> 
                                    </span> 
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-400 dark:text-white">
                                    {assignment.assignment_name} <br />
                                    <span className=" font-extralight text-gray-400"> {assignment.assignment_description} </span>
                                </Table.Cell>
                                <Table.Cell className="text-gray-400">
                                    {getPhonicGroupFromId(assignment.phonic_group_id)}
                                </Table.Cell>
                                <Table.Cell className="text-gray-400">
                                    {getStudyGroupFromId(assignment.study_group_id)}
                                </Table.Cell>
                                <Table.Cell className=" gap-2text-gray-400">
                                    {assignment.archived ?
                                        <>
                                            <FontAwesomeIcon
                                                className="mr-2 text-gray-400"
                                                icon={faArchive}

                                            />
                                            <span className="text-xs text-gray-400"> Archived </span>
                                        </>
                                        : <>
                                            <FontAwesomeIcon
                                                className="mr-2 text-gray-400"
                                                icon={checkDueDate(assignment) ? faLock : faUnlock}
                                                // size="2x"
                                                style={{ color: checkDueDate(assignment) ? "gray" : "green" }}
                                            />
                                            {assignment.due_date ?
                                                <span className="text-xs text-gray-400"> {assignment.due_date} </span> :
                                                <span className="text-xs text-gray-400"> No Due Date </span>
                                            }
                                        </>}
                                </Table.Cell>
                                <Table.Cell
                                    onClick={handleViewReport(assignment)}
                                    className=" hover:text-lime-500 cursor-pointer text-gray-400">
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                    view
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>


                </Table>
            </div>
             { showQR  && <QRDisplay data={ qrData.data } name={ qrData.name} setShowQR={setShowQR} />}
        </div>

    );
};

export default ReportTable;