
import { useSelector } from 'react-redux';
import { getClassAverageScore } from '../../../../features/home/reportSlice';
import React, { useEffect } from 'react'




const ReportAverageCard = () => {

  const {assignmentRecord} = useSelector(state => state.homeReducer);
  
    return (
      <div className="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
         <div class="flex flex-col items-center justify-center h-full">
             <dt class="mb-5 text-5xl font-extrabold text-orange-500">
                 {assignmentRecord ? parseFloat(assignmentRecord.averageScore).toFixed(1) : "-"}
             </dt>
            <dd class="text-orange-500 text-2xl font-extrabold dark:text-orange-400"> average score </dd>
             <dd class="text-gray-400 dark:text-gray-500"></dd>
         </div>
     </div>
    )
}

export default ReportAverageCard; 
