import React from "react";
import ReportDifficaltyCard from "./ReportDifficaltyCard";
import { useSelector } from 'react-redux';

const ReportQuizDifficulty = () => {
    const {assignmentRecord} = useSelector(state => state.homeReducer);
    let report = { ...assignmentRecord.report}
    // conver  assignmentRecord.report object to array 
   report = Object.values(assignmentRecord.report);
    // sort assignmentRecord.report ny avaerage score from low to high
    report.sort((a, b) => (a.averageScore > b.averageScore) ? 1 : -1);
    return (
        <div className="grid grid-cols-1 gap-4 mb-2">
           <div className="w-full justify-start items-start   bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <h6 className=" text-xl font-bold text-gray-900 dark:text-white">Performance (low score to high score)</h6>
                <div className="flex flex-row items-center gap-2" >
                {/* loop report array  */}
                { report.map((key, index) => {
                    return (
                  
                        <ReportDifficaltyCard key={index} report={key} index={index + 1} />
                    )
                })}
                </div>
            </div>
        </div>
    );
};
export default ReportQuizDifficulty;