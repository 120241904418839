import React from "react";
import ReportNav from "./ReportNav";
import ReportList from "./ReportList";
import ReportInfoView from "./ReportInfoContainer/ReportInfoView";
import { useSelector } from 'react-redux';



const ReportView = () => {

    

    // get current report from redux store
    // const currentReport = useSelector(state => state.reportReducer.currentReport);
    const assignmentRecord = useSelector(state => state.homeReducer.assignmentRecord);
    const [showArchived, SetShowArchived] = React.useState(true);

    return (
        <>
        <div className=" bg-white flex flex-col" >
            <ReportNav SetShowArchived={SetShowArchived} showArchived={showArchived} />
            <ReportList showArchived={showArchived}  />
        </div>
        {/* if current report is not null show */}
        {assignmentRecord && <ReportInfoView  />}
        </>
    );
};


export default ReportView;