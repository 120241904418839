import React, { useEffect } from "react";
import { Table } from 'flowbite-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { openStudentReport } from "../../../../features/home/reportSlice";
import { Modal, Button, ListGroup } from 'flowbite-react';
import { useState } from "react";
import { HiSpeakerphone } from "react-icons/hi";
import ReportAssignmentPerformance from "./ReportAssignmentPerformance";


const ReportStudentsTabView = ({showArchived}) => {
    const [openModal, setOpenModal] = useState(undefined);
    const [currentIndex, setCurrentIndex] = useState(0);
    const props = { openModal, setOpenModal };
    // const currentReport = useSelector(state => state.reportReducer.currentReport);
    const assignmentRecord = useSelector(state => state.homeReducer.assignmentRecord);
    const studentAssignmentRecord = useSelector(state => state.homeReducer.studentAssignmentRecord);
    const rank = assignmentRecord.rank;
    const students = assignmentRecord.students;
    const dispatch = useDispatch();
    // get showArchived from props 

    const [showPerformance, setShowPerformance] = useState(false);

    // effect if assignmentRecord is not null then dispatch action to fetch student assignment record
    // effect if studentAssignmentRecord is not null then open student report modal
    useEffect(() => {
        if (studentAssignmentRecord !== null) {
            setOpenModal('size');
        } else {
            setOpenModal(undefined);
        }
    }, [studentAssignmentRecord]);

    // function play sound from url
    const playSound = (url) => {
        console.log(`play sound from url: ${url}`)
        const audio = new Audio(url);
        audio.play();
    };

    // function check is student in rank or not
    const isStudentInRank = (student_id) => {
        if (rank !== undefined) {
            const student = rank.find(student => student.student_id == student_id);
            if (student != undefined) {
                console.log(`\n student.id: ${student_id} || ${student.name} \n in rank \n -------`)
                return true;
            }
        }
        console.log(`------- \n  student.id: ${student_id} \n  not in rank`)
        return false;
    };
    

    const handleViewStudentReport = (id) => {
        console.log(`\n student.id: ${id} || assignment_id : ${assignmentRecord.assignment_id} \n`);
        dispatch({
            type: 'FETCH_ASSIGNMENT_RECORED_BY_STUDENT_ID_AND_ASSIGNMENT_ID', payload: {
                student_id: id,
                assignment_id: assignmentRecord.assignment_id
            }
        });
    };

    const Next = () => {
        if (currentIndex < studentAssignmentRecord[0].phonicsRecords.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const Previous = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <>
        <div className="p-5 pb-20  overflow-y-auto drop-shadow-md" style={{ maxHeight: '65vh' }}>
            <Table hoverable className="table-auto">
                <Table.Head>
                    <Table.HeadCell>
                        NAME
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Email || Student ID
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Rank
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Score
                    </Table.HeadCell>

                    <Table.HeadCell>

                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {rank.map((student_report, index) => (
                        <Table.Row key={student_report._id}>
                            <Table.Cell>
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-10 w-10">
                                        <img className=" h-10 w-10 rounded-full object-cover object-center" src="https://i.pinimg.com/564x/89/0c/0b/890c0bd8bcf73716447dc93c51000274.jpg" alt="" />
                                    </div>
                                    <div className="ml-4">
                                        <div className="text-sm font-medium text-gray-900">
                                            {student_report.name}
                                        </div>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {student_report.email}
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    {index + 1}
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    {student_report.score}
                                </span>
                            </Table.Cell>
                            <Table.Cell onClick={() => {
                                handleViewStudentReport(student_report.student_id)
                            }} className="text-center text-sky-500 hover:text-sky-700 cursor-pointer">
                                <FontAwesomeIcon icon={faFolderOpen} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                     <Table.Row >
                            <Table.Cell className="p-2">
                                <div className="flex items-center">
                                    The following students are missing assignments
                                </div>
                             </Table.Cell>
                     </Table.Row>
             
                       {students.map((student_report, index) => (
                        !isStudentInRank(student_report._id) && 
                        <Table.Row key={student_report._id} className=" bg-slate-100 text-slate-400">
                            <Table.Cell>
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-10 w-10">
                                        <img className=" h-10 w-10 rounded-full object-cover object-center" src="https://i.pinimg.com/564x/89/0c/0b/890c0bd8bcf73716447dc93c51000274.jpg" alt="" />
                                    </div>
                                    <div className="ml-4">
                                        <div className="text-sm font-medium ">
                                            {student_report.name}
                                        </div>
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                {student_report.email}
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                   -
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    -
                                </span>
                            </Table.Cell>
                            <Table.Cell onClick={() => {
                                handleViewStudentReport(student_report.student_id)
                            }} className="text-center text-sky-500 hover:text-sky-700 cursor-pointer">
                                <FontAwesomeIcon icon={faFolderOpen} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
            
                </Table.Body>
            </Table>
        </div>
       { 
        studentAssignmentRecord &&
        <ReportAssignmentPerformance />
        }</>
    )
};


export default ReportStudentsTabView;
