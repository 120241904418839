import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const WordCardComponent = ({showScoreView}) => {
  return (
    <AnimatePresence>
    {  !showScoreView && 
    (<motion.div
      className="w-1/2 h-full p-4"
      initial={{ x: "100%", opacity: 0 }} // Initial position, hidden to the right
      animate={{ x: 0, opacity: 1}}  // Final position, move to the left (in view)
      transition={{ duration: 0.6, delay: 0.35 }} // Animation duration in seconds
      // exit={{ x: "-100%" }} 
    >
      <div className="h-full flex flex-col text-center justify-center rounded-lg">
        <h5 className="text-7xl font-bold leading-none text-gray-900 dark:text-white">
        宇航员
        </h5>
        <p className="text-xl font-medium mt-8 text-gray-500 dark:text-gray-400">
        Yǔháng yuán
        </p>
      </div>
    </motion.div>)
    }
    </AnimatePresence>
  );
};

export default WordCardComponent;