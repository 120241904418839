import React from "react";
import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faSearch, faGraduationCap} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { toggleAddStudentModal } from "../../.././features/studentReducer";

const QuizControlBar = ( { setIsShowExistStudentPopup }) => {
    const dispatch = useDispatch();
    const handlerAddStudentPopup = () => {
        console.log("Toggle Student Popup");
        dispatch(toggleAddStudentModal());
    }


    return (
        <div className="flex justify-between items-center flex-row pb-2 pl-5 pr-5 pt-2">
            <div className="flex justify-between items-center flex-row ">
            <Button onClick={handlerAddStudentPopup}
             color="primary" rounded className="hover:text-sky-500 mr-5">
                <FontAwesomeIcon icon={faCirclePlus} size="xl" className="pr-3" />
                <span className=" font-bold">Create New Students</span>
            </Button>
            <Button 
                onClick={()=>{setIsShowExistStudentPopup(true);}}
                color="primary" rounded className="hover:text-green-500 mr-5">
                <FontAwesomeIcon icon={faCirclePlus} size="xl" className="pr-3" />
                <span className=" font-bold">Add Student to study group</span>
            </Button>
            </div>
            <div className="flex justify-between items-center flex-row ">      
                <label className="relative inline">
                    <span className="sr-only">Search</span>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <FontAwesomeIcon icon={faSearch} className="pr-3" />
                    </span>
                    <input className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search" />
                </label>
            </div>
        </div>

    );
};

export default QuizControlBar;