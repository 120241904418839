import React from "react";
import InfoCardBox from "./InfoCardBox";
import ReportCardName from "./ReportCardName";
import ReportInfoNav from "./ReportInfoNav";
import ReportDashboardTabView from "./ReportInfoContainer/ReportDashboardTabView";
import ReportStudentsTabView from './ReportInfoContainer/ReportStudentsTabView';
import ReportQuestionsTabView from './ReportInfoContainer/ReportQuestionsTabView';
import { useSelector } from 'react-redux';

const ReportDashboardView = () => {

    const {reportViewSubPage} = useSelector(state => state.reportReducer); 

    return (
        <div className=" bg-white shadow-lg z-20  h-full w-full" >
            <div className="h-1/4 flex flex-row bg-cover bg-center bg-[url('https://i.pinimg.com/564x/45/01/1f/45011f19667590f5488579a1ba6d17f1.jpg')]">
                <div className="h-full w-2/3 flex flex-col-reverse ">
                    <ReportInfoNav />
                    <ReportCardName />
                </div>
                <InfoCardBox />
            </div>
            {/* if reportViewSubPage is equa to 'summary' show report dashboard tab view */}
            {reportViewSubPage === 'summary' && <ReportDashboardTabView />}
            {/* if reportViewSubPage is equa to 'students' show student report students tab view */}
            {reportViewSubPage === 'students' && <ReportStudentsTabView />}
            {/* if reportViewSubPage is equa to 'questions' show student report questions tab view */}
            {reportViewSubPage === 'questions' && <ReportQuestionsTabView />}
        </div>
    )
}

export default ReportDashboardView;