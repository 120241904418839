import { createSlice } from "@reduxjs/toolkit";
import study_group_service from "../services/study_group_service";
import { setPopup } from "./globalReducer";

// slice studyGroup 
const studyGroupSlice = createSlice({
    name: "studyGroup",
    initialState: {
        studyGroups: null
    },
    reducers: {
        INIT_STUDY_GROUP: (state, action) => {
            // event get study group 
            state.studyGroups = action.payload;
        },
        TEACHER_GET_STUDY_GROUP: (state, action) => {
            // console.log("** TEACHER_GET_STUDY_GROUP");
            // event get study group
            // get study group 
            // get token from user reducer 
            // study_group_service.getStudyGroups().then((res) => {
            //     console.log("get study group ", res.data);
            //     //if 200 set data  else show error message
            //     if (res.status === 200) {
            //         state.studyGroups = res.data;
            //         console.log("get study group ", res.data);
            //     }else{
            //         // show error message 
            //         // main/setPopup 
            //         setPopup({show: true, title : "Error", message : "fail to get study group", color : "danger"});
            //     }
            // }).catch((err) => {
            //     // show error message 
            //     // main/setPopup 

            //     setPopup({show: true, title : "Error", message : "fail to get study group", color : "danger"});

            // });



                
        }

    }
});

export const { INIT_STUDY_GROUP, TEACHER_GET_STUDY_GROUP } = studyGroupSlice.actions;
export default studyGroupSlice.reducer;