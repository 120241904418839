
import ReactMarkdown from 'react-markdown'
import { useState, useEffect } from 'react'
// import WikiDoc from '../WIKI.md'

const Wiki = () => {
  const [markdown, setMarkdown] = useState(null);
  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(
          'https://rgk-images.s3.ap-southeast-1.amazonaws.com/wiki/WIKI.md'
        );

        if (!response.ok) {
          throw new Error('Failed to fetch markdown file');
        }

        const markdownContent = await response.text();
        setMarkdown(markdownContent);

      } catch (error) { 
        console.log(error);
      }
    };
    fetchMarkdown();
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow overflow-auto">
        <ReactMarkdown className='prose'>{markdown}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Wiki;