import axios from "axios";
import config from "../config.js";

// register a new user

const register = async ( payload) => {
    return await axios.post(config.URI + "/api/user/register", payload);
}

// login a user

const login = async (payload) => {
    try {
    const { email, password } = payload;
    console.log(`login with ${email} and ${password} to ${config.URI}/api/user/login`);
    // http post to /api/login with json body {email: email, password: password}
    const response = await axios.post(
        `${config.URI}/api/user/login`,
        { email, password }
    );

    if (response.status === 200) {
        const user = {
            ...response.data,
        };
        console.log(`login success with ${user.email}`);
        return user;
    } else {
        console.log(`login failed with ${response.status}`);
        return null;
    }
    } catch (e) {
        console.log(e);
        return null;
    }
   
}

// login a user with token

const token_login = async (token) => {
    console.log(`token login with ${token} to ${config.URI}/api/user/token_login`);
    try {
    // http post to /api/login with json body {token: token}
    const response = await axios.post(
        `${config.URI}/api/user/token_login`,
        { token:token }
    );
   console.log(response.status);
    if (response.status === 200) {
        const user = {
            ...response.data,
        };
        console.log(`token login success with ${user.email}`);
        return user;
    } else {
        console.log(`token login failed with ${response.status}`);
        return null;
    }
    } catch (e) {
        console.log(e);
        return null;
    }

}


//
const qr_login = async (code) => {
    const LAVOMERRQ = 'LAVOMERRQ';
    const response = await axios.post(
        `${config.URI}/api/user/qrcode`,
        { code: code }
    );
    if (response.status === 200) {
        const user = {
            ...response.data,
        };
        console.log(`token login success with ${user.email}`);
        return user;
    } else {
        console.log(`token login failed with ${response.status}`);
        return null;
    }                   

}







// logout a user
const logout = () => {
    localStorage.removeItem("user");
};


// export all the functions

const api = {
    register,
    login,
    token_login,
    logout,
    qr_login
};

export default api;
