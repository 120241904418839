import React from 'react';
import HomeTabs from '../components/home/HomeTabs';
import '../css/home.css';
import StudentList from '../components/home/studentTabViews/StudentTabView';

import QuizesView from '../components/home/quizesView/QuizesView';
import SumariesView from '../components/home/SumariseView';
import { useSelector } from 'react-redux';
import ReportView from '../components/home/reportContainer/ReportView';
import SpeechLabsView from '../components/home/speechLabs/SpeechLabView';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { initHomeData } from '../features/home/homeSlice';


const Home = () => {
    const currentTab = useSelector(state => state.homeReducer.value.currentTab);

    // const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch({ type: 'fetchStudentsData'});
    // }, []);

    return (
        <div className="flex flex-col h-screen bg-gray-300">
            <SumariesView />
            <HomeTabs />
            <div className="flex-grow flex-1">
                {currentTab === "students" && <StudentList />} 
                {currentTab === "reports" && <ReportView />}
                {currentTab === 'speechlabs' && <SpeechLabsView />}
                {currentTab === "quizes" && <QuizesView />}
            </div>
        </div>
    );
};

export default Home;