import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
const ReportProgressCard = () => {

    // const {currentReport, currentReportData}   = useSelector(state => state.reportReducer);
    const {assignmentRecord} = useSelector(state => state.homeReducer);
    // find assignment from home assignment list
    const assignment =  useSelector(state => state.homeReducer.assignments.find(assignment => assignment._id === assignmentRecord.assignment_id));
    // calculate percentage of students who have submitted the assignment from assignmentRecord.rank.length and assignmentRecord.students.length 
    const submitRate = (assignmentRecord.rank.length / assignmentRecord.students.length * 100).toFixed(1);
    return (
        <div className="max-w-sm justify-center flex items-center p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div>
                <div className=''>
                    <FontAwesomeIcon icon={faFlagCheckered} size="2xl" />
                </div>
                <div className="mb-3 mt-3">
                    <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                        {assignment ? assignment.assignment_name : "no data"}
                    </h5>
                </div>
                <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                    {assignmentRecord ? assignmentRecord.rank.length : "no data"} students submitted assignment
                    <br />
                    <span className=" font-light text-xs text-gray-500 dark:text-gray-100">
                    {assignmentRecord ? assignmentRecord.students.length : "no data"} students in total
                    </span>
                </p>

                <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-8">
                    <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: `${submitRate}%` }}> {submitRate}% </div>
                </div>
            </div>

        </div>
    );
};

export default ReportProgressCard;