import { createSlice } from '@reduxjs/toolkit';


export const speechLabSlice = createSlice({
    name: 'speechLab',
    initialState: {
        speechLab: [],
        showAddSpeechLabModal: false,
        speechLabAmountToAdd: 0,
        officalPhonics: [],
        officalPhonicsGroup: [],
        myPhonics: [],
        searchPhonics: [],
        publicPhonics: []
    },

    reducers: {
        addSpeechLab: (state, action) => {
            state.speechLab.push(action.payload);
        },
        toggleAddSpeechLabModal: (state, action) => {
            state.showAddSpeechLabModal = !state.showAddSpeechLabModal;
            console.log(`toggleAddSpeechLabModal: ${state.showAddSpeechLabModal}`);
        },
        setOfficalPhonics: (state, action) => { 
            state.officalPhonics = action.payload;
        },
        setOfficalPhonicsGroup: (state, action) => {
            state.officalPhonicsGroup = action.payload;
        },
        setMyPhonics: (state, action) => {
            state.myPhonics = action.payload;
        },
        setSearchPhonics: (state, action) => {
            state.searchPhonics = action.payload;
        },
        setPublicPhonics: (state, action) => {
            state.publicPhonics = action.payload;
        }

    }
});

export const { addSpeechLab, toggleAddSpeechLabModal } = speechLabSlice.actions;
export default speechLabSlice.reducer;